.outline-none,
.outline-none:focus {
  box-shadow: none;
  outline: none;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-pointer * {
  cursor: pointer;
}

/* Max - Width */
.max-width-100 {
  max-width: 100%;
}

/* Border - Radius */
.border-radius-top-none {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.border-radius-bottom-none {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.link {
  background-color: transparent;
  color: #007bff;
  cursor: pointer;
  text-decoration: none;
}

.mainstem-select__menu {
  z-index: 9 !important;
}

.required {
  color: #f00;
  font-size: 10px;
  margin-left: 2px;
  position: absolute;
}
:root {
  --color-primary: $color-primary;
  --color-secondary: $color-secondary;
  --color-success: $color-success;
  --color-info: $color-info;
  --color-warning: $color-warning;
  --color-danger: $color-danger;
  --color-white: $color-white;
  --color-black: $color-black;
  --color-grey: $color-grey;
}
.alert {
  margin-bottom: 0;
}

.alert-light {
  border: 1px solid #dee2e6;
}

.alert-warning {
  border-color: #fbe5a0;
}

.alert-soft-danger {
  background-color: #fce7eb;
  border-color: #f8d7da;
  color: #932e5b;
}

.alert-soft-info {
  background-color: #d4f2ff;
  border-color: #b8daff;
  color: #1983c5;
}

.alert-soft-light {
  background-color: #f7f8f9;
  border: 1px solid #dee2e6;
  color: #bfbfbf;
}

.alert-soft-primary {
  background-color: #ccf0f9;
  border-color: #b8daff;
  color: #017fb4;
}

.alert-soft-secondary {
  background-color: #eef0f2;
  border: 1px solid #dee2e6;
  color: #212529;
}

.alert-soft-success {
  background-color: #ccf6e4;
  border-color: #c3e6cb;
  color: #009171;
}

.alert-soft-warning {
  background-color: #fde6d8;
  border-color: #f7c9b5;
  color: #c46c45;
}

.badge {
  font-size: 14.4px !important;
  padding: 0.35556em 0.71111em;
}

.badge-soft-primary {
  background-color: #ccf0f9;
  color: #017fb4;
}

.badge-soft-warning {
  background-color: #fde6d8;
  color: #c46c45;
}

.badge-soft-success {
  background-color: #ccf6e4;
  color: #009171;
}

.badge-soft-danger {
  background-color: #fce7eb;
  color: #932e5b;
}

.badge-soft-info {
  background-color: #d4f2ff;
  color: #1983c5;
}

.badge-soft-secondary {
  background-color: #eef0f2;
  border: 1px solid #dee2e6;
  color: #212529;
}

.lip-bottom-left {
  margin-bottom: 5px;
  position: relative;
}

.lip-bottom-left::after {
  border: 10px solid transparent;
  border-bottom: 0;
  border-left: 0;
  border-top-color: #d4f2ff;
  bottom: 0;
  content: "";
  height: 0;
  left: 50%;
  margin-bottom: -9px;
  margin-left: -46px;
  position: absolute;
  width: 0;
}

.bg-soft-primary {
  background-color: #ccf0f9;
  color: #017fb4;
}

.bg-soft-warning {
  background-color: #fde6d8;
  color: #c46c45;
}

.bg-soft-success {
  background-color: #ccf6e4;
  color: #009171;
}

.bg-soft-danger {
  background-color: #fce7eb;
  color: #932e5b;
}

.bg-soft-info {
  background-color: #d4f2ff;
  color: #1983c5;
}

.bg-soft-secondary {
  background-color: #eef0f2;
  border: 1px solid #dee2e6;
  color: #212529;
}

:root {
  --color-primary: $color-primary;
  --color-secondary: $color-secondary;
  --color-success: $color-success;
  --color-info: $color-info;
  --color-warning: $color-warning;
  --color-danger: $color-danger;
  --color-white: $color-white;
  --color-black: $color-black;
  --color-grey: $color-grey;
}

.input-group-sm > .custom-select,
.input-group-sm > .form-control,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .input-group-text {
  font-size: 13px;
}

.btn {
  background-color: #fff;
  border-color: #ccc;
  border-radius: 6px;
  border-style: solid;
  border-width: 0.5px;
  cursor: pointer;
  font-size: 13px;
  padding: 5px 15px;
  text-align: center;
  transition: color 200ms ease, background-color 200ms ease;
}
.btn:hover, .btn:active, .btn:focus {
  outline: 0;
  text-decoration: none;
}
.btn:active {
  background: #0192b7;
  border-color: #f3f5f7;
  box-shadow: 0 0 2px 2px rgba(210, 207, 207, 0.2);
}

.disabled {
  background-color: rgb(255, 255, 255);
  box-shadow: none;
  box-shadow: none;
  color: #808080;
  cursor: not-allowed;
}

.btn-default:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 2px 2px rgba(210, 207, 207, 0.2);
}

.btn-default:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled):active {
  box-shadow: 0 0 2px 2px rgba(210, 207, 207, 0.2);
}

.btn-default {
  box-shadow: none;
  color: #000;
}
.btn-default:hover, .btn-default:focus {
  background: #fff;
  box-shadow: none;
  color: black;
}
.btn-default:active, .btn-default:not(:disabled):not(.disabled):active, .btn-default:not(:disabled):not(.disabled):active:focus {
  background: #fff;
  box-shadow: 0 0 2px 2px rgba(210, 207, 207, 0.2);
  color: black;
}

.btn-primary {
  box-shadow: none;
  color: #01b5e2;
}
.btn-primary:hover, .btn-primary:focus {
  background: #fff;
  box-shadow: none;
  color: #018caf;
}
.btn-primary:active, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled):active:focus {
  background: #fff;
  box-shadow: 0 0 2px 2px rgba(210, 207, 207, 0.2);
  color: #01b5e2;
}

.btn-secondary {
  box-shadow: none;
  color: #409;
}
.btn-secondary:hover, .btn-secondary:focus {
  background: #fff;
  box-shadow: none;
  color: #2d0066;
}
.btn-secondary:active, .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled):active:focus {
  background: #fff;
  box-shadow: 0 0 2px 2px rgba(210, 207, 207, 0.2);
  color: #409;
}

.btn-success {
  box-shadow: none;
  color: #00d27a;
}
.btn-success:hover, .btn-success:focus {
  background: #fff;
  box-shadow: none;
  color: #00d27a;
}
.btn-success:active, .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled):active:focus {
  background: #fff;
  box-shadow: 0 0 2px 2px rgba(210, 207, 207, 0.2);
  color: #00d27a;
}

.btn-info {
  box-shadow: none;
  color: #27bcfd;
}
.btn-info:hover, .btn-info:focus {
  background: #fff;
  box-shadow: none;
  color: #02a7ef;
}
.btn-info:active, .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled):active:focus {
  background: #fff;
  box-shadow: 0 0 2px 2px rgba(210, 207, 207, 0.2);
  color: #27bcfd;
}

.btn-warning {
  box-shadow: none;
  color: #f5803e;
}
.btn-warning:hover, .btn-warning:focus {
  background: #fff;
  box-shadow: none;
  color: #c34d0a;
}
.btn-warning:active, .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled):active:focus {
  background: #fff;
  box-shadow: 0 0 2px 2px rgba(210, 207, 207, 0.2);
  color: #f5803e;
}

.btn-danger {
  box-shadow: none;
  color: #e63757;
}
.btn-danger:hover, .btn-danger:focus {
  background: #fff;
  box-shadow: none;
  color: #a3142e;
}
.btn-danger:active, .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled):active:focus {
  background: #fff;
  box-shadow: 0 0 2px 2px rgba(210, 207, 207, 0.2);
  color: #e63757;
}

.card {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  display: flex;
  flex-direction: column;
  min-height: 80px;
}

.card-body {
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
}

.table-footer {
  background-color: #f9f9f9;
  border-radius: 0 0 6px 6px;
  font-size: 12px;
}
.table-footer .react-bootstrap-table-pagination-total {
  font-size: 12px;
}

.card-header {
  align-items: self-start;
  background-color: #f9f9f9;
  border-bottom: 1px solid rgb(204, 204, 204);
  border-radius: 6px 6px 0 0 !important;
  color: #333;
  display: flex;
  flex-direction: column;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: -1px;
  padding: 15px;
  width: 100%;
  z-index: 1;
}
.card-header h5 {
  font-size: 15px;
}
.card-header .row {
  margin: 0;
  width: 100%;
}

.card-footer {
  background-color: #f9fafd;
}

.lg-card-header {
  font-size: 2.0736rem;
}

.modal-backdrop.show {
  opacity: 0.25;
}

.modal-title {
  width: 100%;
}

:root {
  --color-primary: $color-primary;
  --color-secondary: $color-secondary;
  --color-success: $color-success;
  --color-info: $color-info;
  --color-warning: $color-warning;
  --color-danger: $color-danger;
  --color-white: $color-white;
  --color-black: $color-black;
  --color-grey: $color-grey;
}

.nav-tabs {
  align-content: flex-start;
  align-self: flex-start;
  border-bottom: 0;
  display: block;
  font-size: 12px;
  margin-bottom: -1px;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  z-index: 1;
}
.nav-tabs .nav-link {
  align-items: center;
  border: inherit;
  border: 0 none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-width: 0;
  display: flex;
  justify-content: center;
  justify-self: flex-start;
  margin-bottom: 0;
  min-height: 100%;
  min-width: 100%;
  padding: 5px 15px;
}
.nav-tabs .nav-link:hover {
  border: 0 none;
  border-width: 0;
}
.nav-tabs .nav-item {
  background-color: #edf2f9;
  border: 1px solid #d8e2ef;
  color: #344050;
  font-family: "Poppins";
  font-size: 0.83333rem;
  font-weight: normal;
  margin-right: 5px;
  margin-top: 5px;
  opacity: 0.8;
  padding-left: 15px;
  padding-right: 15px;
  transition: all 250ms ease-in;
}
.nav-tabs .nav-item:hover {
  cursor: pointer;
}
.nav-tabs .nav-item.active {
  background: linear-gradient(180deg, #fefeff 75%, #ecf1f8 100%);
  border-bottom: 2px solid #01b5e2;
  color: #01b5e2;
  opacity: 1;
}
.nav-tabs .nav-item a {
  border: 0 none;
  border-width: 0;
}
.nav-tabs .nav-item a:hover {
  border: 0 none;
  border-width: 0;
}

.tab-content {
  background-color: #fafafa;
  border-color: #ccc !important;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  display: flex;
  flex: 1;
  flex: 1;
  flex-direction: column;
  min-height: 100%;
  padding: 25px;
}

[data-class=portlet] .tab-content {
  border-radius: 0 0 6px 6px;
  margin-left: -21px;
  margin-right: -21px;
}

[data-class=portlet] .tabs-portlet-correct {
  bottom: -21px;
  margin-top: -21px;
  position: relative;
}

.col-md-auto .nav-tabs {
  align-content: flex-start;
  align-self: flex-start;
  border-bottom: 0;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  grid-auto-columns: -webkit-max-content;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  grid-column-gap: 2px;
  grid-row-gap: 5px;
  grid-template-columns: -webkit-max-content;
  grid-template-columns: max-content;
  grid-template-rows: auto;
  justify-items: flex-start;
  margin-bottom: 10px;
  margin-left: 0;
  margin-right: -1px;
  margin-top: 10px;
  position: relative;
  z-index: 1;
}

:root {
  --color-primary: $color-primary;
  --color-secondary: $color-secondary;
  --color-success: $color-success;
  --color-info: $color-info;
  --color-warning: $color-warning;
  --color-danger: $color-danger;
  --color-white: $color-white;
  --color-black: $color-black;
  --color-grey: $color-grey;
}

/*-----------------------------------------------
|   Table
-----------------------------------------------*/
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: #f9fafd;
}

.white-space-nowrap {
  white-space: nowrap;
  width: 1px;
}

th {
  font-weight: 600;
}

td .dropdown-toggle::after {
  display: none;
}

.table-dashboard {
  overflow: hidden;
}
.table-dashboard th {
  border-bottom-width: 1px !important;
}

[for^=text-filter-column],
[for^=select-filter-column] {
  display: block;
}

[for^=date-filter-column] input {
  font-size: 1em;
  height: 25px;
  padding: 2px;
}

.filter-label .form-control {
  font-size: 1em;
  height: 25px;
  padding: 2px;
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 181, 226, 0.1);
}

.react-bs-table-sizePerPage-dropdown .dropdown-toggle {
  background-color: #fff;
  border-color: #fff;
  border-radius: 0.2rem;
  box-shadow: 0 0 1px #d0d0d0;
  color: #4d5969;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 0.1875rem 0.75rem;
}

.react-bootstrap-table-page-btns-ul {
  margin-bottom: 0;
}
.react-bootstrap-table-page-btns-ul .page-item.disabled {
  opacity: 25%;
}
.react-bootstrap-table-page-btns-ul .page-item.active .page-link {
  background-color: #fff;
  border-color: #00b5e2;
  color: #00b5e2;
  z-index: 1;
}
.react-bootstrap-table-page-btns-ul .page-item .page-link {
  background-color: #fff;
  border-color: #fff;
  border-radius: 0.2rem;
  box-shadow: 0 0 1px #d0d0d0;
  color: #4d5969;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 0.1875rem 0.75rem;
}

.bg-200 {
  background-color: #edf2f9 !important;
}

.border-200 {
  border-color: #edf2f9 !important;
}

.border-y {
  border-bottom: 1px solid #d8e2ef !important;
  border-top: 1px solid #d8e2ef !important;
}

.table td.fit,
.table th.fit {
  white-space: nowrap;
  width: 1%;
}

.table thead th {
  color: #333;
  cursor: pointer;
  font-size: 11px;
  line-height: 120%;
  padding: 10px 20px;
  text-transform: uppercase;
  vertical-align: top;
}
.table td {
  color: #333;
  font-size: 12px;
  line-height: 120%;
  padding: 10px 20px;
}
.table td small {
  font-size: 100%;
}
.table tbody td {
  padding: 20px !important;
}
.table .form-control {
  border-radius: 2px;
  font-size: 12px;
  height: 25px;
  margin-top: 5px;
  padding: 2px 10px;
  width: auto;
}
.table .filter-label {
  margin-right: 5px;
}
.table .filter {
  display: flex;
}
.table .fs--1 {
  cursor: pointer;
  font-size: 11px;
}
.table .badge {
  display: inline-block !important;
  font-size: 12px !important;
  margin: 0 auto;
  min-width: 114px;
  padding: 0.35556em 0.71111em;
  width: auto;
}

.table-responsive::-webkit-scrollbar {
  background: #fff;
  height: 12px;
  max-height: 8px;
  max-width: 12px;
  min-height: 8px;
  min-width: 12px;
  width: 12px;
}
.table-responsive::-webkit-scrollbar-button {
  display: block;
  height: 26px;
}
.table-responsive::-webkit-scrollbar-thumb, .table-responsive::-webkit-scrollbar-thumb {
  box-shadow: rgba(255, 255, 255, 0) 4px 4px 4px 4px inset;
  cursor: pointer;
}
.table-responsive::-webkit-scrollbar-thumb {
  background: #3b128d;
  border: solid 3px #3b128d;
  border-radius: 100px;
  box-shadow: inset 0 0 3px rgba(153, 153, 153, 0);
}
.table-responsive::-webkit-scrollbar-track, .table-responsive::-webkit-scrollbar-track {
  box-shadow: rgba(0, 0, 0, 0) 0 0 6px inset;
}
div[class*=react-select][class*=control] {
  border-color: hsl(0deg, 0%, 80%);
  box-shadow: none;
  cursor: pointer;
}
div[class*=react-select][class*=control]:hover {
  border-color: hsl(0deg, 0%, 80%);
  box-shadow: none;
  outline: none;
}
div[class*=react-select][class*=menu] {
  cursor: pointer;
  z-index: 999 !important;
}
div[class*=react-select][class*=menu] [class*=menu-list] [class*=option] {
  background-color: #fff;
  cursor: pointer;
}
div[class*=react-select][class*=menu] [class*=menu-list] [class*=option]:focus {
  background-color: #fff;
}
div[class*=react-select][class*=menu] [class*=menu-list] [class*=option][class*=selected] {
  background-color: #001e62;
}
div[class*=react-select][class*=menu] [class*=menu-list] [class*=option]:hover {
  background-color: #00b5e2;
}

.Toastify__toast--success {
  background: #ccf6e4 !important;
  color: #009171;
}

.Toastify__toast--error {
  background: #fce7eb !important;
  color: #932e5b;
}
@import url("https://fonts.googleapis.com/css?family=Open+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
html,
body {
  background-color: #edf2f9;
  display: flex;
  font-family: "Open Sans", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-height: 100%;
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #344050;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0;
}

.text-red {
  color: #f00;
}

.fs--1 {
  font-size: 0.83333rem;
}

.custom-control-label {
  line-height: 1.45rem;
  margin-bottom: 0;
  position: relative;
  vertical-align: top;
}

label {
  font-size: 0.83333rem;
  font-weight: 500;
  letter-spacing: 0.02em;
}
#root {
  background-color: #ecf2f9;
  color: #333;
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 20px;
  min-width: 100%;
}

#ms-nav-top-container-right-search-input {
  align-self: center;
  background-color: transparent;
  border: 1px none #000;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  flex: 0 0 auto;
  font-size: 12px;
  height: 30px;
  line-height: 100%;
  margin-bottom: 0;
  margin-right: 0;
  margin-top: 0;
  max-width: 600px;
  opacity: 0;
  padding-bottom: 0;
  padding-left: 10px;
  padding-top: 0;
  position: relative;
  right: 0;
  transition: all 200ms ease;
  width: 30px;
  z-index: 9999;
}
#ms-nav-top-container-right-search-input:focus {
  background-color: #eeebf5;
  border-color: #7958bd;
  border-style: solid;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.35);
  flex: 0 0 auto;
  height: 40px;
  left: 0;
  margin-left: 0;
  margin-top: 0;
  opacity: 1;
  padding-left: 35px;
  position: relative;
  top: 0;
  width: 100vw;
  z-index: 1;
}
#ms-nav-top-container-right-search-input:focus-visible {
  outline: none;
}

#ms-container {
  background-color: #ecf2f9;
  display: flex;
  flex: 1;
  position: relative;
  z-index: 8;
}
#ms-container #ms-nav-left {
  align-self: stretch;
  background-color: #fff;
  display: block;
  flex: 0 0 auto;
  flex-direction: column;
  font-size: 13px;
  overflow: visible;
  position: relative;
  top: 0;
  transition: width 200ms ease;
  width: 240px;
}
#ms-container #ms-nav-left::-webkit-scrollbar {
  background: rgba(59, 18, 141, 0.0039215686) !important;
  box-shadow: none;
  width: 5px !important;
  z-index: 9999;
}
#ms-container #ms-nav-left::-webkit-scrollbar-track {
  display: none;
}
#ms-container #ms-nav-left::-webkit-scrollbar-thumb {
  background-color: #3b128d;
  border-radius: 100px;
  height: 0;
}
#ms-container #ms-nav-left::-webkit-scrollbar-track-piece {
  background: transparent;
  box-shadow: none;
}
#ms-container #ms-nav-left.closed {
  width: 55px;
}
#ms-container #ms-nav-left.closed #ms-nav-left-wrapper {
  width: 55px;
}
#ms-container #ms-nav-left.closed #ms-nav-left-wrapper:hover {
  width: 240px;
}
@media screen and (max-width: 991px) {
  #ms-container #ms-nav-left.closed #ms-nav-left-wrapper {
    width: 240px;
  }
}
#ms-container #ms-nav-left.closed #ms-nav-left-wrapper #ms-nav-left-branding {
  width: 55px;
}
#ms-container #ms-nav-left.closed #ms-nav-left-wrapper #ms-nav-left-identity {
  display: none;
}
#ms-container #ms-nav-left.closed #ms-nav-left-wrapper .ms-nav-left-link {
  width: 55px;
}
#ms-container #ms-nav-left.closed #ms-nav-left-wrapper .ms-nav-left-link:hover {
  border-right: 2px solid #00affa;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
  width: 240px;
}
#ms-container #ms-container-app {
  display: flex;
  flex: 1;
  position: relative;
}
#ms-container #ms-container-app.no-left {
  margin-left: 0;
}
#ms-container #ms-container-app #ms-container-app-wrapper {
  align-items: center;
  align-self: stretch;
  bottom: 49px;
  display: flex;
  flex: 1;
  flex-direction: column;
  left: 0%;
  opacity: 1;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 15px;
  padding-top: 15px;
  position: absolute;
  right: 0%;
  top: 0%;
}
#ms-container #ms-container-app #ms-container-app-wrapper::-webkit-scrollbar {
  background: rgba(59, 18, 141, 0.1019607843);
  box-shadow: none;
  width: 7px;
  z-index: 9999;
}
#ms-container #ms-container-app #ms-container-app-wrapper::-webkit-scrollbar-thumb {
  background-color: #3b128d;
  border-radius: 100px;
  box-shadow: none;
  height: 10px;
}
#ms-container #ms-container-app #ms-container-app-wrapper::-webkit-scrollbar-track {
  display: none;
}
#ms-container #ms-container-app #ms-container-app-wrapper::-webkit-scrollbar-track-piece {
  background: transparent;
  box-shadow: none;
}
#ms-container #ms-container-app #ms-footer {
  align-items: center;
  background-color: #fafafa;
  background-image: none;
  border-left: 1px solid #ececec;
  border-top: 1px solid #ececec;
  bottom: 1px;
  color: rgba(0, 0, 0, 0.18);
  display: flex;
  flex-direction: row;
  font-family: "Poppins", sans-serif;
  height: 49px;
  justify-content: space-between;
  left: 0;
  padding-bottom: 0;
  padding-right: 30px;
  position: absolute;
  right: 0;
  z-index: 9;
}
#ms-container #ms-container-app #ms-footer #ms-footer-logo-wrapper {
  align-items: center;
  align-self: center;
  color: rgba(0, 0, 0, 0.18);
  cursor: pointer;
  display: flex;
  flex: 0 auto;
  height: 100%;
  margin-left: 20px;
  position: relative;
}
#ms-container #ms-container-app #ms-footer #ms-footer-logo-wrapper:hover {
  color: #3b128d;
  text-decoration: none;
}
#ms-container #ms-container-app #ms-footer #ms-footer-logo-wrapper:hover .cls-1 {
  fill: #00b5e2 !important;
}
#ms-container #ms-container-app #ms-footer #ms-footer-logo-wrapper:hover .cls-2 {
  fill: #409 !important;
}
#ms-container #ms-container-app #ms-footer #ms-footer-logo-wrapper .footer-logo-text {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
  margin-right: 5px;
  transition: color 200ms ease;
}
#ms-container #ms-container-app #ms-footer #ms-footer-logo-wrapper .footer-svg-embed {
  width: 90px;
}
#ms-container #ms-container-app #ms-footer #ms-footer-logo-wrapper .footer-svg-embed .cls-1 {
  fill: rgba(0, 0, 0, 0.18);
  transition: all ease 0.2s;
}
#ms-container #ms-container-app #ms-footer #ms-footer-logo-wrapper .footer-svg-embed .cls-2 {
  fill: rgba(0, 0, 0, 0.18);
  transition: all ease 0.2s;
}
#ms-container #ms-container-app #ms-footer #ms-footer-links-wrapper {
  display: grid;
  flex: 1;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  grid-row-gap: 16px;
  grid-template-columns: auto;
  grid-template-rows: auto;
  justify-content: end;
  margin-right: 50px;
}
#ms-container #ms-container-app #ms-footer #ms-footer-links-wrapper .ms-footer-links-link {
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  transition: color 200ms ease;
}
#ms-container #ms-container-app #ms-footer #ms-footer-links-wrapper .ms-footer-links-link:hover {
  color: #3b128d;
}
#ms-container #ms-container-app #ms-footer #ms-footer-links-wrapper #ms-footer-version-number {
  align-items: center;
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
  display: block;
  flex: 0 0 auto;
  font-family: "Poppins", sans-serif;
  font-size: 11px;
  font-weight: 600;
  margin-left: 10px;
  text-transform: uppercase;
  transition: color 200ms ease;
}

@media screen and (max-width: 991px) {
  #ms-container #ms-container-app #ms-container-app-wrapper {
    bottom: 0;
  }
  #ms-container #ms-nav-left {
    bottom: 0;
    left: -250px;
    position: absolute;
    transition: right 200ms ease, left 200ms ease, width 200ms ease;
  }
  #ms-container #ms-nav-left.closed {
    left: 0;
    right: auto;
    width: 240px;
    z-index: 99;
  }
  #ms-container #ms-nav-left.closed #ms-nav-left-wrapper #ms-nav-left-branding {
    width: 240px;
  }
  #ms-container #ms-nav-left.closed #ms-nav-left-wrapper .ms-nav-left-link {
    width: 240px;
  }
  #ms-footer {
    display: none !important;
  }
}
.ms-theme-1 #ms-nav-left {
  box-shadow: 0 0 12px -3px rgba(0, 0, 0, 0.51);
}
#ms-nav-top.ms-theme-2 {
  border-bottom: 0 none;
  z-index: 9;
}

#ms-container.ms-theme-2 {
  z-index: 2;
}

.ms-theme-2 #ms-nav-top-containers #ms-nav-top-container-left {
  background-color: #2b1570;
  box-shadow: 0 0 12px -3px rgba(0, 0, 0, 0.51);
  min-width: 240px;
}
@media screen and (max-width: 650px) {
  .ms-theme-2 #ms-nav-top-containers #ms-nav-top-container-left {
    min-width: auto;
    width: 120px;
  }
}
.ms-theme-2 #ms-nav-top-containers #ms-nav-top-container-left #ms-nav-top-icon-collapse {
  color: #fafafa;
}
.ms-theme-2 #ms-nav-top-containers #ms-nav-top-container-left #ms-nav-top-app-name {
  color: #fafafa !important;
}
.ms-theme-2 #ms-nav-left {
  background-color: #2b3dab;
  box-shadow: 0 0 12px -3px rgba(0, 0, 0, 0.51);
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  z-index: 3;
}
.ms-theme-2 #ms-nav-left-wrapper {
  background-color: #2b3dab;
  z-index: 2;
}
.ms-theme-2 #ms-nav-left-wrapper::-webkit-scrollbar-thumb {
  background-color: #00affa;
}
.ms-theme-2 #ms-nav-left-wrapper #ms-nav-left-branding {
  background-color: #fafafa;
}
.ms-theme-2 #ms-nav-left-wrapper #ms-nav-left-identity {
  background-color: transparent;
}
.ms-theme-2 #ms-nav-left-wrapper #ms-nav-left-identity div {
  color: #fafafa;
}
.ms-theme-2 #ms-nav-left-wrapper #ms-nav-left-links {
  background-color: transparent;
  border: 0 none;
}
.ms-theme-2 #ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link {
  background-color: transparent;
  border: 0 none;
  color: #fff;
}
.ms-theme-2 #ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link:hover {
  background-color: rgba(255, 255, 255, 0.1098039216);
  color: #fff;
}
.ms-theme-2 #ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-link-toggle {
  color: #fff;
}
.ms-theme-2 #ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-link-toggle .ms-nav-left-link-line {
  background-color: #00affa;
}
.ms-theme-2 #ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-link-toggle:hover {
  color: #00affa;
}
.ms-theme-2 #ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-link-toggle:hover .ms-nav-left-link-line {
  opacity: 1;
}
.ms-theme-2 #ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-dropdown {
  background-color: rgba(0, 0, 0, 0.19);
}
.ms-theme-2 #ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-dropdown .ms-nav-left-dropdown-content .ms-nav-left-dropdown-link:hover .ms-nav-left-dropdown-link-icon-wrapper,
.ms-theme-2 #ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-dropdown .ms-nav-left-dropdown-content .ms-nav-left-dropdown-link:hover .ms-nav-left-dropdown-link-text-wrapper {
  color: #00affa;
}
.ms-theme-2 #ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-dropdown .ms-nav-left-dropdown-content .ms-nav-left-dropdown-link .ms-nav-left-dropdown-link-icon-wrapper,
.ms-theme-2 #ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-dropdown .ms-nav-left-dropdown-content .ms-nav-left-dropdown-link .ms-nav-left-dropdown-link-text-wrapper {
  color: #fafafa;
}
.ms-theme-2 #ms-nav-left-wrapper.closed #ms-nav-left-links .ms-nav-left-link:hover .ms-nav-left-link-toggle {
  background-color: rgba(55, 33, 120, 0.9019607843) !important;
  color: #fff !important;
}
.ms-theme-2 #ms-nav-left-wrapper.closed #ms-nav-left-links .ms-nav-left-link:hover .ms-nav-left-link-toggle .ms-nav-left-link-line {
  background-color: #00affa !important;
}
.ms-theme-2 #ms-nav-left-wrapper.closed #ms-nav-left-links .ms-nav-left-link:hover .ms-nav-left-dropdown {
  background-color: #1a296c !important;
}
#ms-nav-left-wrapper {
  align-items: flex-end;
  align-self: flex-start;
  bottom: 0;
  direction: rtl;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  left: 0;
  overflow: hidden;
  overflow-y: overlay;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 200ms ease;
}
#ms-nav-left-wrapper > div {
  direction: ltr;
}
#ms-nav-left-wrapper #ms-nav-left-branding {
  align-items: center;
  align-self: flex-end;
  background-color: #fff;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  min-height: 54px;
  overflow: hidden;
  padding: 10px;
  position: relative;
  transition: width 200ms ease;
  width: 100%;
}
#ms-nav-left-wrapper #ms-nav-left-branding #ms-nav-left-branding-image {
  background-color: #fcfcfc;
  border-radius: 6px;
  flex: 0 0 auto;
  max-height: 140px;
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
}
#ms-nav-left-wrapper #ms-nav-left-links {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  transition: width 200ms ease;
}
#ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link {
  align-self: stretch;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  transition: width 200ms ease, color 200ms ease;
  width: 240px;
  z-index: 99;
}
#ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-link-toggle {
  align-self: stretch;
  cursor: pointer;
  display: flex;
  flex: 1;
  min-height: 50px;
  position: relative;
  transition: width 200ms ease;
}
#ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-link-toggle .ms-nav-left-link-bg {
  background-color: rgba(59, 18, 141, 0.1);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
#ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link {
  border-top: solid 1px #ecf2f9;
  direction: ltr;
  display: flex;
  flex-direction: column;
  position: relative;
  text-decoration: none;
  transition: width 200ms ease, color 200ms ease;
}
#ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link:hover {
  background-color: #fff;
}
#ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-link-toggle {
  color: #333;
  cursor: pointer;
  display: flex;
  flex: 1;
  min-height: 50px;
  position: relative;
  transition: width 200ms ease;
}
#ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-link-toggle.active .ms-nav-left-link-bg,
#ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-link-toggle.active .ms-nav-left-link-line, #ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-link-toggle:hover .ms-nav-left-link-bg,
#ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-link-toggle:hover .ms-nav-left-link-line {
  opacity: 1;
}
#ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-link-toggle:hover {
  color: #3b128d;
  width: 240px;
}
#ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-link-toggle.active {
  color: #00affa;
}
#ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-link-toggle.active .ms-nav-left-link-bg {
  background-color: rgba(0, 175, 250, 0.11);
}
#ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-link-toggle.active .ms-nav-left-link-line {
  background-color: #00affa;
  opacity: 1;
}
#ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-link-toggle .ms-nav-left-link-bg {
  background-color: rgba(121, 88, 189, 0.09);
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 500ms ease;
}
#ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-link-toggle .ms-nav-left-link-line {
  align-self: stretch;
  background-color: #3b128d;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  bottom: 0;
  min-width: 5px;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 200ms ease;
  width: 5px;
  z-index: 99;
}
#ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-link-toggle .ms-nav-left-link-icon-wrapper {
  align-items: center;
  align-self: center;
  display: flex;
  justify-content: center;
  min-width: 55px;
  width: 55px;
  z-index: 1;
}
#ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-link-toggle .ms-nav-left-link-text-wrapper {
  align-items: center;
  align-self: stretch;
  cursor: pointer;
  display: flex;
  flex: 1;
  opacity: 1;
  position: relative;
  text-decoration: none;
  transition: opacity 200ms ease;
  z-index: auto;
}
#ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-link-toggle .ms-nav-left-link-text-wrapper .ms-nav-left-link-text {
  flex: 1;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  line-height: 13px;
  text-decoration: none;
  white-space: nowrap;
}
#ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-link-toggle .ms-nav-left-link-arrow-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 15px;
  width: 30px;
}
#ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-link-toggle .ms-nav-left-link-arrow-wrapper .ms-nav-left-link-arrow {
  font-size: 16px;
  transition: transform 200ms ease;
}
#ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-link-toggle .ms-nav-left-link-arrow-wrapper .ms-nav-left-link-arrow.rotate {
  transform: rotate(-180deg);
}
#ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-dropdown {
  align-self: stretch;
  background-color: rgba(245, 245, 245, 0.47);
  color: #778ca2;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 400;
  height: auto;
  opacity: 1;
  overflow: hidden;
  position: relative;
  transition: height 200ms ease;
  width: 240px;
}
#ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-dropdown.closed {
  display: flex;
  height: 0;
}
#ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-dropdown .ms-nav-left-dropdown-content {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  padding-top: 15px;
}
#ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-dropdown .ms-nav-left-dropdown-content .ms-nav-left-dropdown-link {
  border-radius: 2px;
  color: #505050;
  display: flex;
  font-weight: 400;
  height: auto;
  line-height: 100%;
  padding-bottom: 6px;
  padding-top: 6px;
  text-decoration: none;
  transition: all 200ms ease;
}
#ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-dropdown .ms-nav-left-dropdown-content .ms-nav-left-dropdown-link:hover {
  color: #00affa;
}
#ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-dropdown .ms-nav-left-dropdown-content .ms-nav-left-dropdown-link.active {
  color: #3b128d;
}
#ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-dropdown .ms-nav-left-dropdown-content .ms-nav-left-dropdown-link .ms-nav-left-dropdown-link-icon-wrapper {
  align-items: center;
  align-self: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  margin-right: 3px;
  width: 52px;
  z-index: 1;
}
#ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-dropdown .ms-nav-left-dropdown-content .ms-nav-left-dropdown-link .ms-nav-left-dropdown-link-icon-wrapper .ms-nav-left-dropdown-link-icon {
  background-color: transparent;
  border-bottom: 1px solid transparent;
  border-radius: 0;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  z-index: 9;
}
#ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-dropdown .ms-nav-left-dropdown-content .ms-nav-left-dropdown-link .ms-nav-left-dropdown-link-text-wrapper {
  align-items: center;
  align-self: stretch;
  cursor: pointer;
  display: flex;
  flex: 1;
  opacity: 1;
  position: relative;
  text-decoration: none;
  transition: opacity 200ms ease;
  z-index: auto;
}
#ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-tooltip {
  border-radius: 4px;
  display: flex;
  flex: 0 0 auto;
  left: 100%;
  margin-left: 5px;
  max-width: 140px;
  opacity: 1;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  transition: left 200ms ease, opacity 200ms ease;
  z-index: -1;
}
#ms-nav-left-wrapper #ms-nav-left-links .ms-nav-left-link .ms-nav-left-tooltip.closed {
  left: -200%;
  opacity: 0;
}
#ms-nav-left-wrapper #ms-nav-left-identity {
  align-self: stretch;
  background-color: #fafafa;
  border-top: 1px solid #ececec;
  bottom: 0;
  font-size: 12px;
  left: 0;
  padding: 10px;
  position: relative;
  right: 0;
  text-align: center;
  z-index: 99;
}
#ms-nav-left-wrapper #ms-nav-left-identity #ms-nav-left-identity-company-name {
  font-weight: 700;
}
#ms-nav-left-wrapper::-webkit-scrollbar {
  background: rgba(59, 18, 141, 0.1019607843);
  box-shadow: none;
  opacity: 0.6;
  width: 5px;
  z-index: 9999;
}
#ms-nav-left-wrapper::-webkit-scrollbar-thumb {
  background-color: #3b128d;
  border-radius: 100px;
  box-shadow: none;
  height: 10px;
  opacity: 0.5;
}
#ms-nav-left-wrapper::-webkit-scrollbar-track {
  display: none;
}
#ms-nav-left-wrapper::-webkit-scrollbar-track-piece {
  background: transparent;
  box-shadow: none;
}
#login-page-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;
  width: 100vw;
}
#login-page-wrapper #login-box {
  border-radius: 6px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.24);
  display: flex;
  flex: 1;
  max-width: 650px;
  position: relative;
  z-index: 1;
}
#login-page-wrapper #login-box hr {
  align-self: stretch;
  background-color: rgba(0, 0, 0, 0.1);
  border: 0;
  height: 1px;
  margin-bottom: 10px;
  width: 100%;
}

.flex-vertical {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.align-center {
  align-items: center;
  justify-content: center;
}

.submit-wrapper {
  display: flex;
  flex: 0 auto;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 15px;
}

.form-block {
  display: flex;
  flex: 1;
  flex-direction: column;
}

/* Mobile Responsive */
@media screen and (max-width: 767px) {
  #login-box {
    flex-direction: column;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
  }
}
#login-circles-1 {
  bottom: 100%;
  height: 300px;
  left: 100%;
  position: absolute;
  transform: translate(-50%, 60%);
  transform: translate(-50%, 60%);
  transform: translate(-50%, 60%);
  width: 300px;
  z-index: -1;
}
#login-circles-1 #login-circles-1---1 {
  border-color: #5214a1;
  border-radius: 100%;
  border-style: solid;
  border-width: 6px;
  height: 75%;
  left: 0;
  position: absolute;
  top: 0;
  width: 75%;
}
#login-circles-1 #login-circles-1---2 {
  border-color: #fff;
  border-radius: 100%;
  border-style: solid;
  border-width: 4px;
  height: 15%;
  position: absolute;
  right: 0;
  top: 0;
  width: 15%;
}
#login-circles-1 #login-circles-1---3 {
  border-color: #00affa;
  border-radius: 100%;
  border-style: solid;
  border-width: 5px;
  bottom: 0;
  height: 25%;
  left: 50%;
  opacity: 0.25;
  position: absolute;
  width: 25%;
}
#login-circles-2 {
  height: 250px;
  position: absolute;
  right: 100%;
  top: 100%;
  transform: translate(50%, -60%);
  transform: translate(50%, -60%);
  transform: translate(50%, -60%);
  width: 250px;
  z-index: -1;
}
#login-circles-2 #login-circles-2---1 {
  border-color: #fff;
  border-radius: 100%;
  border-style: solid;
  border-width: 6px;
  bottom: 0;
  height: 50%;
  position: absolute;
  right: 0;
  width: 50%;
}
#login-circles-2 #login-circles-2---2 {
  border-color: #fff;
  border-radius: 100%;
  border-style: solid;
  border-width: 4px;
  bottom: 50%;
  height: 15%;
  left: 0;
  position: absolute;
  width: 15%;
}
#login-circles-2 #login-circles-2---3 {
  border-color: #00affa;
  border-radius: 100%;
  border-style: solid;
  border-width: 5px;
  height: 25%;
  left: 5%;
  opacity: 0.25;
  position: absolute;
  top: 0;
  width: 25%;
}
#login-left {
  background-color: #00affa;
  background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.17));
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  color: #fff;
  display: flex;
  flex: 0 auto;
  flex-direction: column;
  padding: 40px 30px;
  position: relative;
  width: 275px;
}
#login-left .logo {
  margin-bottom: 21px;
  width: 175px;
}
#login-left .login-left-p {
  margin-bottom: 20px;
  text-align: center;
}
#login-left hr {
  background-color: rgba(255, 255, 255, 0.17) !important;
  margin-bottom: 25px !important;
}

@media screen and (max-width: 767px) {
  #login-left {
    border-radius: 0 0 6px 6px;
    order: 1;
    width: 100%;
  }
}
#login-right {
  background-color: #fff;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 445px;
  padding: 39px;
  position: relative;
}
#login-right .step-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  #login-right {
    border-radius: 6px 6px 0 0;
    min-height: auto;
  }
}
.action-bar-links {
  display: flex;
  flex-direction: column;
  margin-top: 0;
}

@media screen and (max-width: 767px) {
  .action-bar-links {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
.action-button {
  border-color: #0192d1;
  color: #0192d1;
  cursor: pointer;
  font-size: 11px;
  line-height: 100%;
  margin-bottom: 5px;
  margin-top: 5px;
}
.action-button:hover {
  border-color: rgba(45, 0, 102, 0.4784313725);
  color: #2d0066;
}
.login-submit-button {
  align-items: center;
  background-color: #5214a1;
  background-image: linear-gradient(180deg, hsla(0deg, 0%, 100%, 0), hsla(0deg, 0%, 100%, 0));
  border-color: #5214a1;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.49);
  color: #fff;
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 8px;
  text-decoration: none;
  transition: all 200ms ease;
}
.login-submit-button:hover, .login-submit-button:active, .login-submit-button:disabled,
.login-submit-button .disabled {
  background-color: #5214a1;
  border-color: #5214a1;
  border-style: solid;
  border-width: 1px;
  color: #fff;
  opacity: 1;
}
.login-submit-button:disabled,
.login-submit-button .disbaled {
  cursor: not-allowed;
}

.loading-message-text {
  display: inline-block;
  line-height: 100%;
  position: relative;
  top: 1px;
}

.button-loading {
  max-width: 20px;
}

.submit-loading-wrapper {
  align-items: center;
  display: none;
}
.field-label {
  color: #5e6f81;
  font-size: 12px;
  font-weight: 600;
  line-height: 100%;
}

.field-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;
  position: relative;
}
.field-wrapper .text-field {
  border-color: rgba(94, 111, 129, 0.26);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 0;
}
.field-wrapper .text-field.error {
  background-color: #fff1f1;
  border-color: #f00;
  display: block;
}
.error {
  color: #f11a1a;
  font-size: 12px;
  font-style: italic;
}
.app-logo {
  align-self: center;
  border-radius: 6px;
  height: 50px;
  margin-bottom: 0;
  max-height: 100px;
  -o-object-fit: contain;
     object-fit: contain;
  object-fit: contain;
  width: 100%;
}

.user-wrapper {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.user-avatar {
  align-self: center;
  border-radius: 3px;
  height: 40px;
  margin-right: 5px;
  overflow: hidden;
  transition: border-color 200ms ease;
  transition: border-color 200ms ease;
}
.user-avatar:hover {
  border-color: #00affa;
}

.user-image {
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  object-fit: contain;
  width: 100%;
}

.user-name-wrapper {
  align-items: center;
  display: flex;
}

.user-name {
  font-weight: 600;
}
.back-button {
  align-items: center;
  background-color: #5214a1;
  border-color: #5214a1;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.49);
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 19px;
  justify-content: center;
  margin-right: 3px;
  min-height: 35px;
  opacity: 1;
  padding: 9px;
  text-decoration: none;
  transition: background-color 200ms ease;
}
.back-button:hover, .back-button:active, .back-button:disabled,
.back-button .disabled {
  background-color: #5214a1;
  border-color: #5214a1;
  border-style: solid;
  border-width: 1px;
  color: #fff;
  opacity: 1;
}
.back-button:disabled,
.back-button .disbaled {
  cursor: not-allowed;
}
.back-button .back-button-text {
  font-family: LineAwesome, sans-serif;
  font-size: 12px;
  font-weight: 900;
}
.leaflet-container {
  z-index: 1;
}

.map-leaflet-text-distance {
  fill: #409;
  font: 13px "Poppins", sans-serif;
}
.chatWindow {
  background: rgb(233, 229, 229);
  border-radius: 5px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
}

.chatInputWrapper {
  display: grid;
  grid-row-end: 190;
  grid-row-start: 190;
}

/* M E S S A G E S */
.chat {
  background: none;
  height: 300px;
  list-style: none;
  margin: 0;
  margin-bottom: 10px;
  margin-top: 10px;
  max-width: 98%;
  overflow: scroll;
  padding: 0 0 10px;
  scroll-behavior: smooth;
}

.chat li {
  display: flex;
  overflow: hidden;
  padding: 0.5rem;
}

.chat .avatar {
  display: block;
  position: relative;
  z-index: 2;
}

.chat .avatar img {
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  user-select: none;
  user-select: none;
}

.chat .uid img {
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  user-select: none;
  user-select: none;
}

.chat .day {
  box-shadow: inset 20px 0 0 #e5e5e5, inset -20px 0 0 #e5e5e5, inset 0 -2px 0 #d7d7d7;
  color: #c0c0c0;
  cursor: default;
  display: block;
  height: 20px;
  line-height: 38px;
  margin-bottom: 20px;
  margin-top: 5px;
  position: relative;
  text-align: center;
  text-shadow: 7px 0 0 #e5e5e5, 6px 0 0 #e5e5e5, 5px 0 0 #e5e5e5, 4px 0 0 #e5e5e5, 3px 0 0 #e5e5e5, 2px 0 0 #e5e5e5, 1px 0 0 #e5e5e5, 1px 0 0 #e5e5e5, 0 0 0 #e5e5e5, -1px 0 0 #e5e5e5, -2px 0 0 #e5e5e5, -3px 0 0 #e5e5e5, -4px 0 0 #e5e5e5, -5px 0 0 #e5e5e5, -6px 0 0 #e5e5e5, -7px 0 0 #e5e5e5;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  user-select: none;
  user-select: none;
}

.other .msg {
  border-top-left-radius: 0;
  box-shadow: -1px 2px 0 #d4d4d4;
  order: 1;
}

.other::before {
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-left-color: transparent;
  content: "";
  height: 0;
  left: 40px;
  position: relative;
  right: 0;
  top: 0;
  width: 0;
}

.self {
  align-items: flex-end;
  justify-content: flex-end;
}

.self .msg {
  border-bottom-right-radius: 0;
  box-shadow: 1px 2px 0 #d4d4d4;
  order: 1;
}

.self .avatar {
  order: 2;
}

.self .avatar::after {
  border: 5px solid #fff;
  border-right-color: transparent;
  border-top-color: transparent;
  bottom: 19px;
  box-shadow: 0 2px 0 #d4d4d4;
  content: "";
  display: inline-block;
  height: 0;
  position: relative;
  right: 0;
  width: 0;
}

.msg {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.07);
  font-size: 0.9rem;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding: 10px;
}

.msg p {
  color: rgb(81, 84, 255);
  font-size: 0.7rem;
  margin: 0 0 0.2rem;
}

.msg img {
  border-radius: 5px;
  box-shadow: 0 0 3px #eee;
  cursor: default;
  display: block;
  position: relative;
  -webkit-touch-callout: none;
  transition: all 0.4s cubic-bezier(0.565, -0.26, 0.255, 1.41);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  user-select: none;
  user-select: none;
  width: 450px;
}

@media screen and (max-width: 800px) {
  .msg img {
    width: 300px;
  }
}
@media screen and (max-width: 550px) {
  .msg img {
    width: 200px;
  }
}
.msg time {
  color: #ccc;
  cursor: default;
  float: right;
  font-size: 0.7rem;
  margin-top: 3px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  user-select: none;
  user-select: none;
}

.msg time::before {
  color: #ddd;
  content: " ";
  display: inline-block;
  font-family: FontAwesome;
  margin-right: 4px;
}

.chatWindow::-webkit-scrollbar {
  background: #e5e5e5;
  height: 12px;
  max-height: 12px;
  max-width: 12px;
  min-height: 12px;
  min-width: 12px;
  width: 12px;
}

.chatWindow::-webkit-scrollbar-thumb {
  background: rgb(48, 87, 158);
  border: solid 3px #e5e5e5;
  border-radius: 100px;
  box-shadow: inset 0 0 3px #999;
}

.chatWindow::-webkit-scrollbar-thumb:hover {
  background: #b0b0b0;
  box-shadow: inset 0 0 3px #888;
}

.chatWindow::-webkit-scrollbar-thumb:active {
  background: #aaa;
  box-shadow: inset 0 0 3px #7f7f7f;
}

.chatWindow::-webkit-scrollbar-button {
  display: block;
  height: 26px;
}

/* T Y P E */
input.textarea {
  background: #fafafa;
  border: 0 none;
  color: #666;
  font-weight: 400;
  height: 50px;
  outline: none;
  padding-left: 55px;
  padding-right: 55px;
  width: 100%;
}
.stepper-box {
  background-color: #fff;
  border: 1px solid #ddd;
  width: 100%;
}

/* Compact View Idea */
.stepper-box .compact {
  height: 40px;
}

.nav .compact {
  height: 39px;
}

.stepper-box .stepper-steps {
  border-bottom: 1px solid #ddd;
}

.stepper-box .stepper-steps ul {
  overflow: hidden;
}

.stepper-box .stepper-steps ul li div {
  background: #f5f5f5;
  color: #999;
  padding: 10px 0 15px 45px;
  position: relative;
}

.stepper-box .stepper-steps ul li div span {
  font-size: 13px;
}

.stepper-box .stepper-steps ul li:first-child div {
  padding-left: 15px;
}

.stepper-box .stepper-steps ul li div::before {
  border-bottom: 50px solid transparent;
  border-left: 30px solid #ddd;
  border-top: 50px solid transparent;
  content: " ";
  left: 100%;
  margin-left: 1px;
  margin-top: -50px;
  position: absolute;
  top: 50%;
  z-index: 1;
}

.stepper-box .stepper-steps ul li div::after {
  border-bottom: 50px solid transparent;
  border-left: 30px solid #f5f5f5;
  border-top: 50px solid transparent;
  content: " ";
  left: 100%;
  margin-top: -50px;
  position: absolute;
  top: 50%;
  z-index: 2;
}

.stepper-box .stepper-steps ul li.stepper-done div {
  background: #20a8d8 !important;
  border-color: #20a8d8 !important;
  color: #fff !important;
}

.stepper-box .stepper-steps ul li.stepper-done div::after {
  border-left: 30px solid #20a8d8;
}

.stepper-box .stepper-steps ul li.stepper-active div {
  background: #167495 !important;
  border-color: #167495 !important;
  color: #fff !important;
}

.stepper-box .stepper-steps ul li.stepper-active div::after {
  border-left: 30px solid #167495;
}

.stepper-box .step-component {
  align-items: center;
  display: flex;
  height: 200px;
  justify-content: center;
  padding: 20px;
}

.stepper-box .btn-component {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.wysiwyg-wrapper {
  display: block !important;
  width: 100% !important;
}

.wysiwyg-editor {
  border: 1px solid #f1f1f1 !important;
  border-radius: 2px !important;
  min-height: 275px !important;
  padding: 5px !important;
}
.ms-nav-top-middle-menu-link {
  align-items: center;
  align-self: center;
  border-radius: 6px;
  color: #333;
  cursor: pointer;
  display: flex;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  padding: 8px 10px;
  transition: color 200ms ease, background-color 200ms ease;
}
.ms-nav-top-middle-menu-link.active {
  background-color: rgba(0, 181, 226, 0.14);
  color: #00b5e2;
}
.ms-nav-top-middle-menu-link:hover {
  background-color: rgba(59, 18, 141, 0.09);
  color: #3b128d;
  text-decoration: none;
}
.ms-nav-top-middle-menu-link .ms-nav-top-middle-menu-icon {
  font-size: 13px;
  margin-right: 5px;
}
.ms-nav-top-middle-menu-link .ms-nav-top-middle-menu-text {
  font-size: 13px;
}
.ms-nav-top-container-right-cta-button {
  align-items: center;
  align-self: center;
  background-color: #31aa86;
  background-image: linear-gradient(139deg, hsla(0deg, 0%, 100%, 0), hsla(0deg, 0%, 100%, 0.4));
  border-color: #31aa86;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: "Poppins", sans-serif;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.7px;
  margin-left: 5px;
  padding: 2px 6px;
  text-align: center;
  text-transform: uppercase;
  transition: color 200ms ease, background-color 200ms ease;
}
.ms-nav-top-container-right-cta-button:hover {
  background-color: #02f384;
  color: #1b664f;
}
.ms-nav-top-container-right-cta-button .ms-nav-top-container-right-cta-icon {
  font-size: 11px;
  margin-right: 5px;
}
.ms-nav-top-container-right-cta-button .ms-nav-top-container-right-cta-text {
  font-family: "Poppins", sans-serif;
  font-size: 11px;
}
.ms-nav-top-container-right-icon-container {
  margin-left: 10px;
}
.ms-nav-top-container-right-icon-container:hover {
  text-decoration: none;
}
.ms-nav-top-container-right-icon-container:hover .ms-nav-top-container-right-icon-toggle .ms-nav-top-container-right-icon-toggle-icon {
  font-size: 20px;
}
.ms-nav-top-container-right-icon-container:hover .ms-nav-top-container-right-icon-toggle .ms-nav-top-container-right-icon-toggle-count {
  background-color: #00affa;
  font-size: 14px;
  line-height: 15px;
}
.ms-nav-top-container-right-icon-container .ms-nav-top-container-right-icon-toggle {
  cursor: pointer;
  display: flex;
  position: relative;
}
.ms-nav-top-container-right-icon-container .ms-nav-top-container-right-icon-toggle .ms-nav-top-container-right-icon-toggle-icon {
  align-items: center;
  color: rgb(51, 51, 51);
  display: flex;
  font-size: 16px;
  height: 20px;
  justify-content: center;
  line-height: 16px;
  margin-right: 3px;
  position: relative;
  transition: all ease-in-out 100ms;
  width: 20px;
  z-index: 1;
}
.ms-nav-top-container-right-icon-container .ms-nav-top-container-right-icon-toggle .ms-nav-top-container-right-icon-toggle-count {
  align-items: center;
  align-self: center;
  background-color: #a488db;
  border-radius: 2px;
  color: #fff;
  display: flex;
  font-size: 10px;
  font-weight: 600;
  justify-content: center;
  line-height: 10px;
  padding: 3px 4px;
  position: relative;
  transition: all ease-in-out 100ms;
  z-index: 2;
}
.ms-shop {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -15px;
}
.ms-shop .ms-shop-container {
  align-content: flex-start;
  align-self: stretch;
  display: flex;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  padding: 25px;
}
.ms-shop .ms-shop-container .ms-shop-container-left {
  display: block;
  flex: 0 0 auto;
  grid-auto-columns: auto;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  margin-right: 15px;
  position: relative;
  transition: width 200ms ease;
  width: 300px;
  z-index: 1;
}
.ms-shop .ms-shop-container .ms-shop-container-left.toggled {
  overflow: hidden;
  transition: width 200ms ease, overflow ease 2s;
  width: 0;
}
.ms-shop .ms-shop-container .ms-shop-container-right {
  display: flex;
  flex: 1;
  flex-direction: column;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto auto;
}

@media screen and (max-width: 991px) {
  .ms-shop .ms-shop-container .ms-shop-container-left {
    background: #fafafa;
    overflow: hidden;
    position: absolute;
    top: 109px;
    transition: all ease 0.2s;
    width: 0;
    z-index: 9;
  }
  .ms-shop .ms-shop-container .ms-shop-container-left.toggled {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3803921569);
    padding: 0;
    padding: 10px;
    width: calc(100% - 50px) !important;
  }
}
.ms-product-ad-wrapper {
  background-color: #fafafa;
  border-radius: 6px;
  box-shadow: inset 0 0 0 7px #fff, 0 0 7px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  grid-area: Area;
  justify-content: center;
  max-height: 350px;
  padding: 15px;
  position: relative;
  transform: scale(0.95);
  transition: all 0.2s ease;
  width: 100%;
}
.ms-product-ad-wrapper:hover {
  transform: scale(1.025);
}
.ms-product-ad-wrapper .ms-product-ad-tag {
  align-items: flex-start;
  border-top-left-radius: 6px;
  border-top-right-radius: 0;
  color: #fff;
  display: flex;
  font-size: 17px;
  height: 40px;
  justify-content: flex-end;
  left: 0;
  padding-top: 0;
  position: absolute;
  top: 0;
  width: 40px;
  z-index: 1;
}
.ms-product-ad-wrapper .ms-product-ad-tag .ms-product-ad-tag-bg-wrapper {
  border-top-left-radius: 6px;
  border-top-right-radius: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: -1px;
  width: 40px;
}
.ms-product-ad-wrapper .ms-product-ad-tag .ms-product-ad-tag-bg-wrapper .ms-product-ad-tag-bg {
  border-right: 40px solid transparent;
  border-top: 40px solid #fabc00;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.ms-product-ad-wrapper .ms-product-ad-tag .ms-product-ad-tag-text-box {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 11px;
  font-weight: 700;
  justify-content: center;
  left: 4px;
  position: absolute;
  text-transform: uppercase;
  top: 5px;
  transform: rotate(-45deg);
  white-space: nowrap;
}
.ms-product-ad-wrapper .ms-product-ad-tag .ms-product-ad-tag-text-box .ms-product-ad-tag-icon {
  display: none;
  flex: 0 0 auto;
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 7px;
  -o-object-fit: contain;
     object-fit: contain;
}
.ms-product-ad-wrapper .ms-product-ad-image {
  align-self: center;
  justify-self: center;
  max-height: 300px;
  max-width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.ms-shop-categories {
  background-color: #fafafa;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  position: relative;
}
.ms-shop-categories .ms-shop-categories-header-wrapper {
  padding: 15px;
  padding-bottom: 0;
}
.ms-shop-categories .ms-shop-categories-header-wrapper .ms-shop-categories-header {
  border-bottom: 1px dotted #bbb;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.ms-shop-categories .ms-shop-categories-header-wrapper .ms-shop-categories-header .ms-shop-categories-header-text {
  border-bottom: 1px solid #00affa;
  margin-bottom: -1px;
  padding-bottom: 8px;
  padding-right: 10px;
}
.ms-shop-categories .ms-shop-categories-link {
  align-items: center;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
}
.ms-shop-categories .ms-shop-categories-link:hover {
  cursor: pointer;
}
.ms-shop-categories .ms-shop-categories-link:hover .ms-shop-categories-link-text {
  color: #00affa;
}
.ms-shop-categories .ms-shop-categories-link:last-child {
  border-bottom: 0 none;
}
.ms-shop-categories .ms-shop-categories-link .ms-shop-categories-link-toggle {
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid #ccc;
  display: flex;
  padding: 5px 15px 5px 10px;
}
.ms-shop-categories .ms-shop-categories-link .ms-shop-categories-link-toggle .ms-shop-categories-link-icon {
  align-items: center;
  background-color: #fff;
  border: solid 1px #ecf2f9;
  display: flex;
  height: 35px;
  justify-content: center;
  margin-left: 5px;
  width: 35px;
}
.ms-shop-categories .ms-shop-categories-link .ms-shop-categories-link-toggle .ms-shop-categories-link-icon img {
  max-height: 30px;
  max-width: 30px;
}
.ms-shop-categories .ms-shop-categories-link .ms-shop-categories-link-toggle .ms-shop-categories-link-text {
  display: flex;
  flex: 1;
  font-size: 13px;
  font-weight: 500;
  padding-left: 10px;
}
.ms-shop-categories .ms-shop-categories-link .ms-shop-categories-link-toggle .ms-shop-categories-link-count {
  background-color: #3b128d;
  border-radius: 3px;
  color: #fff;
  flex: 0 auto;
  font-size: 10px;
  font-weight: 500;
  line-height: 100%;
  margin-right: 10px;
  padding: 5px;
  text-align: right;
  text-transform: capitalize;
}
.ms-shop-categories .ms-shop-categories-link .ms-shop-categories-link-toggle .ms-shop-categories-link-caret {
  padding-left: 0;
  padding-right: 0;
  transition: transform 200ms ease;
}
.ms-shop-categories .ms-shop-categories-link .ms-shop-categories-link-toggle.open .ms-shop-categories-link-caret {
  padding-left: 0;
  padding-right: 0;
  transform: rotate(-180deg);
}
.ms-shop-categories .ms-shop-categories-link .ms-shop-categories-link-toggle.open .ms-shop-categories-link-menu .ms-shop-categories-link2 .ms-shop-categories-link2-menu {
  margin-bottom: 5px;
}
.ms-shop-categories .ms-shop-categories-link .ms-shop-categories-link-menu {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.ms-shop-categories .ms-shop-categories-link .ms-shop-categories-link-menu .ms-shop-categories-link2 {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.ms-shop-categories .ms-shop-categories-link .ms-shop-categories-link-menu .ms-shop-categories-link2:last-child {
  border-bottom: 1px solid #ecf2f9;
}
.ms-shop-categories .ms-shop-categories-link .ms-shop-categories-link-menu .ms-shop-categories-link2 .ms-shop-categories-link2-toggle {
  align-items: center;
  align-self: stretch;
  background-color: #fff;
  border: 1px solid #ecf2f9;
  border-bottom: 0 none;
  display: flex;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-top: 5px;
}
.ms-shop-categories .ms-shop-categories-link .ms-shop-categories-link-menu .ms-shop-categories-link2 .ms-shop-categories-link2-toggle .ms-shop-categories-link2-icon {
  align-items: center;
  background-color: #fff;
  border: solid 1px #ecf2f9;
  display: flex;
  height: 35px;
  justify-content: center;
  width: 35px;
}
.ms-shop-categories .ms-shop-categories-link .ms-shop-categories-link-menu .ms-shop-categories-link2 .ms-shop-categories-link2-toggle .ms-shop-categories-link2-icon img {
  max-height: 30px;
  max-width: 30px;
}
.ms-shop-categories .ms-shop-categories-link .ms-shop-categories-link-menu .ms-shop-categories-link2 .ms-shop-categories-link2-toggle .ms-shop-categories-link2-text {
  display: flex;
  flex: 1;
  font-size: 12px;
  font-weight: 500;
  padding-left: 10px;
}
.ms-shop-categories .ms-shop-categories-link .ms-shop-categories-link-menu .ms-shop-categories-link2 .ms-shop-categories-link2-toggle .ms-shop-categories-link2-text:hover {
  color: #00affa;
}
.ms-shop-categories .ms-shop-categories-link .ms-shop-categories-link-menu .ms-shop-categories-link2 .ms-shop-categories-link2-toggle .ms-shop-categories-link2-count {
  background-color: #3b128d;
  border-radius: 3px;
  color: #fff;
  flex: 0 auto;
  font-size: 10px;
  font-weight: 500;
  line-height: 100%;
  margin-right: 10px;
  padding: 5px;
  text-align: right;
  text-transform: capitalize;
}
.ms-shop-categories .ms-shop-categories-link .ms-shop-categories-link-menu .ms-shop-categories-link2 .ms-shop-categories-link2-toggle .ms-shop-categories-link2-caret {
  padding-left: 0;
  padding-right: 5px;
  transition: transform 200ms ease;
}
.ms-shop-categories .ms-shop-categories-link .ms-shop-categories-link-menu .ms-shop-categories-link2 .ms-shop-categories-link2-toggle.open .ms-shop-categories-link2-caret {
  padding-left: 5px;
  padding-right: 0;
  transform: rotate(-180deg);
}
.ms-shop-categories .ms-shop-categories-link .ms-shop-categories-link-menu .ms-shop-categories-link2 .ms-shop-categories-link2-menu {
  align-self: stretch;
  background-color: #fafbfc;
  border: 1px solid #ecf2f9;
  display: flex;
  margin-bottom: 5px;
  padding: 10px 10px 10px 20px;
}
.ms-shop-categories .ms-shop-categories-link .ms-shop-categories-link-menu .ms-shop-categories-link2 .ms-shop-categories-link2-menu .ms-shop-categories-link3-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
}
.ms-shop-categories .ms-shop-categories-link .ms-shop-categories-link-menu .ms-shop-categories-link2 .ms-shop-categories-link2-menu .ms-shop-categories-link3-wrapper .ms-shop-categories-link3-link {
  align-self: stretch;
  color: #333;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-weight: 400;
  margin: 5px;
}
.ms-shop-categories .ms-shop-categories-link .ms-shop-categories-link-menu .ms-shop-categories-link2 .ms-shop-categories-link2-menu .ms-shop-categories-link3-wrapper .ms-shop-categories-link3-link:hover {
  color: #00affa;
}
.ms-shop-categories .ms-shop-categories-link .ms-shop-categories-link-menu .ms-shop-categories-link2 .ms-shop-categories-link2-menu .ms-shop-categories-link3-wrapper .ms-shop-categories-link3-link .ms-shop-categories-link3-link-text {
  align-self: stretch;
  display: flex;
  flex: 1;
}
.ms-shop-categories .ms-shop-categories-link .ms-shop-categories-link-menu .ms-shop-categories-link2 .ms-shop-categories-link2-menu .ms-shop-categories-link3-wrapper .ms-shop-categories-link3-link .ms-shop-categories-link3-link-count {
  background-color: rgba(59, 18, 141, 0.09);
  border-radius: 3px;
  display: flex;
  font-size: 10px;
  padding: 0 5px;
}
.ms-shop-filter-wrapper {
  background-color: #fafafa;
  border-color: #ccc;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  display: grid;
  grid-auto-columns: auto;
  grid-column-gap: 16px;
  grid-row-gap: 10px;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  margin-top: 30px;
  padding: 20px;
}
.ms-shop-filter-wrapper .ms-shop-filter-wrapper-header {
  border-bottom: 1px dotted #bbb;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.ms-shop-filter-wrapper .ms-shop-filter-wrapper-header .ms-shop-filter-wrapper-header-text {
  border-bottom: 1px solid #00affa;
  margin-bottom: -1px;
  padding-bottom: 8px;
  padding-right: 10px;
}

.ms-shop-attribute-count {
  background-color: #3b128d;
  border-radius: 3px;
  color: #fff;
  flex: 0 1 auto;
  font-size: 10px;
  font-weight: 500;
  line-height: 100%;
  margin-right: 10px;
  padding: 5px;
  text-align: right;
  text-transform: capitalize;
}
.price-slider-tooltip {
  border-bottom: 1px dotted #444;
  display: inline-block;
  margin-left: 22px;
  position: absolute;
  z-index: 999;
}
.price-slider-tooltip .tooltiptext {
  background-color: #444;
  border-radius: 6px;
  bottom: 150%;
  color: #f0f0f0;
  left: 50%;
  margin-left: -60px;
  opacity: 0.8;
  padding: 5px 0;
  position: absolute;
  text-align: center;
  width: 100px;
  z-index: 1;
}
.price-slider-tooltip .tooltiptext::after {
  border-color: #444 transparent transparent;
  border-style: solid;
  border-width: 5px;
  content: "";
  left: 50%;
  margin-left: -5px;
  position: absolute;
  top: 100%;
}
.ms-shop-header {
  align-self: center;
  background-color: #fafafa;
  border-bottom: 1px solid #ececec;
  border-left: 1px solid #ececec;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.ms-shop-header .ms-shop-header-title {
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  padding-left: 15px;
}
.ms-shop-header .ms-shop-header-title .ms-shop-header-title-text {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 600;
}
.ms-shop-header .ms-shop-header-search {
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 6px;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  margin-left: 30px;
  margin-right: 30px;
  padding-left: 20px;
  padding-right: 20px;
}
.ms-shop-header .ms-shop-header-search input {
  background-color: hsla(0deg, 0%, 100%, 0);
  border: 1px none #000;
  font-size: 12px;
  margin-bottom: 0;
  min-height: 38px;
  outline: none;
  width: 100%;
}
.ms-shop-header .ms-shop-header-catalog {
  align-items: center;
  display: flex;
}
.ms-shop-header .ms-shop-header-catalog .ms-shop-header-catalog-label {
  font-size: 12px;
  padding-right: 5px;
}
.ms-shop-header .ms-shop-header-catalog .ms-shop-header-catalog-select {
  min-width: 200px;
}
.ms-shop-pagination-wrapper {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.ms-shop-pagination-wrapper .ms-shop-pagination-breadcrumbs {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  margin-bottom: 5px;
}
.ms-shop-pagination-wrapper .ms-shop-pagination-breadcrumbs .ms-shop-pagination-breadcrumb {
  cursor: pointer;
  font-size: 11px;
  text-transform: uppercase;
  transition: color 200ms ease, opacity 200ms ease;
}
.ms-shop-pagination-wrapper .ms-shop-pagination-breadcrumbs .ms-shop-pagination-breadcrumb.base {
  font-weight: 500;
  opacity: 1;
}
.ms-shop-pagination-wrapper .ms-shop-pagination-breadcrumbs .ms-shop-pagination-breadcrumb-divider {
  font-size: 9px;
  margin-left: 5px;
  margin-right: 5px;
  opacity: 0.41;
  text-transform: uppercase;
}
.ms-shop-pagination-wrapper .ms-shop-pagination-breadcrumbs .ms-shop-pagination-results-text {
  align-items: flex-end;
  align-self: center;
  display: flex;
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  margin-left: 5px;
  margin-right: 10px;
  position: relative;
}
.ms-shop-pagination-wrapper .ms-shop-pagination-breadcrumbs .ms-shop-pagination-results-text strong {
  color: #24385b;
  font-weight: 500;
}
.ms-shop-pagination-wrapper .ms-shop-pagination {
  display: flex;
  flex-direction: row;
}
.ms-shop-pagination-wrapper .ms-shop-pagination .ms-shop-pagination-size-wrapper {
  display: flex;
  margin-right: 15px;
  min-width: 150px;
}

@media screen and (max-width: 991px) {
  .ms-shop-pagination-wrapper .ms-shop-pagination-breadcrumbs {
    align-self: flex-start;
    display: block;
  }
  .ms-shop-pagination-wrapper .ms-shop-pagination {
    align-self: stretch;
    justify-content: space-between;
  }
  .ms-shop-pagination-breadcrumb {
    display: inline-block !important;
  }
  .ms-shop-pagination-breadcrumb-divider {
    display: inline-block !important;
  }
}
.catalogbrowser-pagination .pagination {
  justify-content: flex-end;
  margin-bottom: 0;
}
.catalogbrowser-pagination .pagination .page-item.active .page-link {
  background-color: #fff;
  border-color: #01b5e2;
  color: #01b5e2;
  z-index: 1;
}
.catalogbrowser-pagination .pagination .page-item .page-link:hover {
  background-color: #f9fafd;
  border-color: #01b5e2;
}
.ms-product-card-grid {
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-areas: "Area Area" ". ." ". ." "Area2 Area2";
  grid-template-columns: 1fr 1fr;
  margin-bottom: 5px;
  margin-top: 5px;
}

@media screen and (max-width: 991px) {
  .ms-product-card-grid {
    display: grid;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-template-areas: "Area Area ." ". . ." ". . ." "Area2 Area2 .";
    grid-template-columns: 1fr 1fr 1fr;
  }
  .ms-shop-pagination-wrapper {
    flex-direction: column;
  }
  .filter-collapse::after {
    content: "Filters";
    display: block;
    font-size: 14px;
    margin-left: 5px;
    text-transform: uppercase;
  }
  .filter-collapse {
    border: 1px solid #3b128d;
    border-radius: 6px;
    color: #3b128d;
    margin-left: auto;
    order: 3;
    width: 100px !important;
  }
}
@media screen and (max-width: 761px) {
  .ms-product-card-grid {
    display: grid;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-template-areas: "Area Area" ". ." ". ." "Area2 Area2";
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 650px) {
  .ms-product-card-grid {
    display: grid;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-template-areas: "Area" "." "." "Area2";
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 478px) {
  .ms-product-card-grid {
    display: grid;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-template-areas: "Area" "." "." "Area2";
    grid-template-columns: 1fr;
  }
  .ms-shop-pagination {
    align-items: center;
  }
  .ms-shop-pagination-wrapper .ms-shop-pagination .ms-shop-pagination-size-wrapper {
    margin-bottom: 5px;
    margin-right: 0;
  }
}
@media screen and (min-width: 1280px) {
  .ms-product-card-grid {
    display: grid;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-template-areas: ". Area Area" ". . ." ". . ." "Area2 Area2 .";
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 1440px) {
  .ms-product-card-grid {
    display: grid;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-template-areas: ". . Area Area" ". . . ." ". . . ." "Area2 Area2 . .";
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 1920px) {
  .ms-product-card-grid {
    display: grid;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-template-areas: ". . . Area Area" ". . . . ." "Area2 Area2 . . .";
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 2100px) {
  .ms-product-card-grid {
    display: grid;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-template-areas: ". . . . . Area Area" ". . . . . . ." ". . . . . . ." "Area2 Area2 . . . . .";
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
.ms-product-shell {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  transition: all 200ms ease;
}
.ms-product-shell.product-delay-1 {
  -webkit-animation-delay: 10ms;
          animation-delay: 10ms;
}
.ms-product-shell.product-delay-2 {
  -webkit-animation-delay: 90ms;
          animation-delay: 90ms;
}
.ms-product-shell.product-delay-3 {
  -webkit-animation-delay: 170ms;
          animation-delay: 170ms;
}
.ms-product-shell.product-delay-4 {
  -webkit-animation-delay: 250ms;
          animation-delay: 250ms;
}
.ms-product-shell:hover {
  border-color: #00affa;
  border-width: 1px;
  box-shadow: #00affa 0 0 5px;
  transform: scale3d(1.025, 1.025, 1.025);
}
.ms-product-shell.alt-color:hover {
  border-color: #3b128d;
  border-width: 1px;
  box-shadow: #3b128d 0 0 5px;
  transform: scale3d(1.025, 1.025, 1.025);
}
.ms-product-shell .ms-product-shell-wrapper {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 350px;
  justify-content: flex-start;
  padding: 25px;
  position: relative;
  width: 100%;
}
.ms-product-shell .ms-product-shell-wrapper .ms-product-category {
  align-self: flex-start;
  color: #656565;
  flex: 0 0 auto;
  font-size: 11px;
  margin-bottom: 10px;
  text-decoration: none;
  transition: color 200ms ease;
}
.ms-product-shell .ms-product-shell-wrapper .ms-product-name {
  flex: 0 0 auto;
  font-size: 13px;
  font-weight: 500;
  line-height: 110%;
  margin-bottom: 10px;
  transition: color 200ms ease;
}
.ms-product-shell .ms-product-shell-wrapper .ms-product-image-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
}
.ms-product-shell .ms-product-shell-wrapper .ms-product-image-wrapper img {
  align-self: flex-end;
  border-radius: 6px;
  bottom: 0;
  left: 0;
  max-height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  position: absolute;
  right: 0;
  width: 100%;
}
.ms-product-shell .ms-product-shell-wrapper .ms-product-bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}
.ms-product-shell .ms-product-shell-wrapper .ms-product-bottom .ms-product-bottom-price-call-out {
  align-self: stretch;
  background-color: rgba(255, 255, 255, 0);
  color: #3b128d;
  display: flex;
  font-size: 11px;
  justify-content: space-between;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}
.ms-product-shell .ms-product-shell-wrapper .ms-product-bottom .ms-product-bottom-price-call-out .ms-product-bottom-price-call-out-text {
  align-self: flex-start;
  flex: 0 0 auto;
  font-weight: 500;
  line-height: 100%;
  position: relative;
  text-transform: capitalize;
}
.ms-product-shell .ms-product-shell-wrapper .ms-product-bottom .ms-product-bottom-price-wrapper {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.ms-product-shell .ms-product-shell-wrapper .ms-product-bottom .ms-product-bottom-price-wrapper .ms-product-bottom-price {
  font-size: 14px;
  font-weight: 500;
}
.ms-product-shell .ms-product-shell-wrapper .ms-product-bottom .ms-product-bottom-price-wrapper .ms-product-bottom-price-separator {
  color: #ccc;
  flex: 1;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}
.ms-product-shell .ms-product-shell-wrapper .ms-product-bottom .ms-product-bottom-button {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-left: -8px;
  margin-right: -8px;
  margin-top: 10px;
  position: relative;
}
.ms-product-shell .ms-product-shell-wrapper .ms-product-bottom .ms-product-bottom-button .ms-product-bottom-button-wrapper {
  align-items: center;
  background-color: #eaeaea;
  background-image: linear-gradient(180deg, transparent, transparent);
  border-radius: 100px;
  color: #333;
  display: flex;
  flex: 1;
  font-size: 19px;
  height: 30px;
  justify-content: flex-start;
  padding: 3px;
  position: relative;
  transition: color 200ms ease, background-color 200ms ease;
  width: 30px;
}
.ms-product-shell .ms-product-shell-wrapper .ms-product-bottom .ms-product-bottom-button .ms-product-bottom-button-wrapper:hover {
  background-color: #7958bd;
  background-image: linear-gradient(90deg, rgba(0, 175, 250, 0.34), rgba(59, 18, 141, 0.23));
  color: #fff;
}
.ms-product-shell .ms-product-shell-wrapper .ms-product-bottom .ms-product-bottom-button .ms-product-bottom-button-wrapper .ms-product-bottom-button-icon {
  align-items: center;
  align-self: stretch;
  background-color: #00affa;
  border: 1px solid #fff;
  border-radius: 100px;
  color: #fff;
  display: flex;
  flex: 0 0 auto;
  font-size: 15px;
  height: 24px;
  justify-content: center;
  width: 24px;
}
.ms-product-shell .ms-product-shell-wrapper .ms-product-bottom .ms-product-bottom-button .ms-product-bottom-button-wrapper .ms-product-bottom-button-text {
  align-self: center;
  flex: 1;
  font-size: 14px;
  font-weight: 400;
  margin-left: 2px;
  text-align: center;
}
.ms-product-shell .ms-product-shell-wrapper .ms-product-bottom .ms-product-bottom-features {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  min-height: 55px;
}
.ms-product-shell .ms-product-shell-wrapper .ms-product-bottom .ms-product-bottom-features .ms-product-bottom-feature {
  align-self: stretch;
  color: #666;
  display: flex;
  font-size: 11px;
  line-height: 100%;
  margin-top: 7px;
  position: relative;
  text-align: left;
  white-space: normal;
}
.ms-product-shell .ms-product-shell-wrapper .ms-product-bottom .ms-product-bottom-features .ms-product-bottom-feature.green {
  color: #07860b;
  font-weight: 600;
}
.ms-product-shell .ms-product-shell-wrapper .ms-product-tag {
  align-items: flex-start;
  border-top-right-radius: 6px;
  color: #fff;
  display: flex;
  font-size: 17px;
  height: 30px;
  justify-content: flex-end;
  padding-right: 4px;
  padding-top: 2px;
  position: absolute;
  right: -1px;
  top: -1px;
  width: 30px;
}
.ms-product-shell .ms-product-shell-wrapper .ms-product-tag .product-tag-bg-wrapper {
  border-top-right-radius: 6px;
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
}
.ms-product-shell .ms-product-shell-wrapper .ms-product-tag .product-tag-bg-wrapper .product-tag-bg {
  border-left: 30px solid rgba(7, 134, 11, 0);
  border-top: 30px solid #3b128d;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
}
.ms-product-shell .ms-product-shell-wrapper .ms-product-tag .product-tag-bg-wrapper .product-tag-bg.alt-color {
  border-top-color: #00affa;
}
.ms-product-shell .ms-product-shell-wrapper .ms-product-tag .product-tag-icon {
  font-family: LineAwesome, sans-serif;
  height: 15px;
  -o-object-fit: contain;
     object-fit: contain;
  object-fit: contain;
  position: relative;
  right: -1px;
  width: 12px;
}
.ms-products-rows {
  align-content: flex-start;
  display: grid;
  flex: 1;
  grid-auto-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  margin-bottom: 10px;
  margin-top: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
}
.ms-products-rows .ms-product-row-wrapper {
  background-color: #fff;
  border-color: #ccc;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  display: flex;
  height: 100%;
  justify-content: space-between;
  min-height: 110px;
  padding: 20px;
  position: relative;
  transition: border-color 200ms ease;
  width: 100%;
}
.ms-products-rows .ms-product-row-wrapper .ms-product-row-left {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 200px;
}
.ms-products-rows .ms-product-row-wrapper .ms-product-row-left .ms-product-row-image {
  align-self: stretch;
  bottom: 0%;
  flex: 0 0 auto;
  height: 100%;
  left: 0%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: 50% 0%;
     object-position: 50% 0%;
  position: absolute;
  right: 0%;
  top: 0%;
  width: 100%;
}
.ms-products-rows .ms-product-row-wrapper .ms-product-row-middle {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}
.ms-products-rows .ms-product-row-wrapper .ms-product-row-middle .ms-product-row-category {
  align-self: flex-start;
  color: #656565;
  flex: 0 0 auto;
  font-size: 11px;
  margin-bottom: 3px;
  text-decoration: none;
  text-transform: capitalize;
  transition: color 200ms ease;
}
.ms-products-rows .ms-product-row-wrapper .ms-product-row-middle .ms-product-row-title {
  cursor: pointer;
  flex: 0 0 auto;
  font-size: 15px;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 10px;
  max-width: 700px;
  transition: color 200ms ease;
}
.ms-products-rows .ms-product-row-wrapper .ms-product-row-middle .ms-product-row-title:hover {
  color: #00affa;
}
.ms-products-rows .ms-product-row-wrapper .ms-product-row-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  width: 30%;
}
.ms-products-rows .ms-product-row-wrapper .ms-product-row-right .ms-product-row-right-content {
  align-self: center;
  display: flex;
  flex-direction: column;
  min-width: 180px;
}
.ms-products-rows .ms-product-row-wrapper .ms-product-row-right .ms-product-row-right-content .ms-product-bottom-price-call-out {
  align-self: stretch;
  background-color: rgba(255, 255, 255, 0);
  color: #3b128d;
  display: flex;
  font-size: 11px;
  justify-content: space-between;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}
.ms-products-rows .ms-product-row-wrapper .ms-product-row-right .ms-product-row-right-content .ms-product-bottom-price-call-out .ms-product-bottom-price-call-out-text {
  align-self: flex-start;
  flex: 0 0 auto;
  font-weight: 500;
  line-height: 100%;
  position: relative;
  text-transform: capitalize;
}
.ms-products-rows .ms-product-row-wrapper .ms-product-row-right .ms-product-row-right-content .ms-product-bottom-price-wrapper {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
}
.ms-products-rows .ms-product-row-wrapper .ms-product-row-right .ms-product-row-right-content .ms-product-bottom-price-wrapper .ms-product-bottom-price {
  font-size: 14px;
  font-weight: 500;
}
.ms-products-rows .ms-product-row-wrapper .ms-product-row-right .ms-product-row-right-content .ms-product-bottom-price-wrapper .ms-product-bottom-price-separator {
  color: #ccc;
  flex: 1;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}
.ms-products-rows .ms-product-row-wrapper .ms-product-row-right .ms-product-row-right-content .ms-product-bottom-button {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-left: -8px;
  margin-right: -8px;
  margin-top: 10px;
  position: relative;
}
.ms-products-rows .ms-product-row-wrapper .ms-product-row-right .ms-product-row-right-content .ms-product-bottom-button .ms-product-bottom-button-wrapper {
  align-items: center;
  background-color: #eaeaea;
  background-image: linear-gradient(180deg, transparent, transparent);
  border-radius: 100px;
  color: #333;
  display: flex;
  flex: 1;
  font-size: 19px;
  height: 30px;
  justify-content: flex-start;
  padding: 3px;
  position: relative;
  transition: color 200ms ease, background-color 200ms ease;
  width: 30px;
}
.ms-products-rows .ms-product-row-wrapper .ms-product-row-right .ms-product-row-right-content .ms-product-bottom-button .ms-product-bottom-button-wrapper:hover {
  background-color: #7958bd;
  background-image: linear-gradient(90deg, rgba(0, 175, 250, 0.34), rgba(59, 18, 141, 0.23));
  color: #fff;
}
.ms-products-rows .ms-product-row-wrapper .ms-product-row-right .ms-product-row-right-content .ms-product-bottom-button .ms-product-bottom-button-wrapper .ms-product-bottom-button-icon {
  align-items: center;
  align-self: stretch;
  background-color: #00affa;
  border: 1px solid #fff;
  border-radius: 100px;
  color: #fff;
  display: flex;
  flex: 0 0 auto;
  font-size: 15px;
  height: 24px;
  justify-content: center;
  width: 24px;
}
.ms-products-rows .ms-product-row-wrapper .ms-product-row-right .ms-product-row-right-content .ms-product-bottom-button .ms-product-bottom-button-wrapper .ms-product-bottom-button-text {
  align-self: center;
  flex: 1;
  font-size: 14px;
  font-weight: 400;
  margin-left: 2px;
  text-align: center;
}
.ms-products-rows .ms-product-row-wrapper .ms-product-row-right .ms-product-row-right-content .ms-product-bottom-features {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
}
.ms-products-rows .ms-product-row-wrapper .ms-product-row-right .ms-product-row-right-content .ms-product-bottom-features .ms-product-bottom-feature {
  align-self: stretch;
  color: #666;
  display: flex;
  font-size: 11px;
  line-height: 100%;
  margin-top: 7px;
  position: relative;
  text-align: left;
  white-space: normal;
}
.ms-products-rows .ms-product-row-wrapper .ms-product-row-right .ms-product-row-right-content .ms-product-bottom-features .ms-product-bottom-feature.green {
  color: #07860b;
  font-weight: 600;
}
.ms-products-rows .ms-product-row-wrapper .ms-product-tag {
  align-items: flex-start;
  border-top-right-radius: 6px;
  color: #fff;
  display: flex;
  font-size: 17px;
  height: 30px;
  justify-content: flex-end;
  padding-right: 4px;
  padding-top: 2px;
  position: absolute;
  right: -1px;
  top: -1px;
  width: 30px;
}
.ms-products-rows .ms-product-row-wrapper .ms-product-tag .product-tag-bg-wrapper {
  border-top-right-radius: 6px;
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
}
.ms-products-rows .ms-product-row-wrapper .ms-product-tag .product-tag-bg-wrapper .product-tag-bg {
  border-left: 30px solid rgba(7, 134, 11, 0);
  border-top: 30px solid #3b128d;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
}
.ms-products-rows .ms-product-row-wrapper .ms-product-tag .product-tag-bg-wrapper .product-tag-bg.alt-color {
  border-top-color: #00affa;
}
.ms-products-rows .ms-product-row-wrapper .ms-product-tag .product-tag-icon {
  font-family: LineAwesome, sans-serif;
  height: 15px;
  -o-object-fit: contain;
     object-fit: contain;
  object-fit: contain;
  position: relative;
  right: -1px;
  width: 12px;
}
.modal {
  cursor: pointer;
}
.modal .modal-dialog {
  cursor: default;
}

.ms-supplier-window {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
}
.ms-supplier-window .modal-content {
  border: 1px solid #fafbfc;
}
.ms-supplier-window .ms-out-of-stock {
  color: rgb(255, 0, 0);
  font-weight: 600;
}
.ms-supplier-window .ms-supplier-window-content {
  align-self: stretch;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 9px 8px rgba(0, 0, 0, 0.34);
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-fixed-top {
  background-color: #fff;
  border-radius: 6px 6px 0 0;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  overflow: hidden;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  transition: height 200ms ease;
  z-index: 9;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-fixed-top .ms-sponsored-supplier {
  border-bottom: 3px solid #00affa;
  display: grid;
  flex: 0 0 auto;
  grid-auto-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-columns: 0.5fr 1fr;
  grid-template-rows: auto;
  justify-content: space-between;
  min-height: 110px;
  padding: 20px;
  position: relative;
  width: 100%;
  z-index: 1;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-fixed-top .ms-sponsored-supplier .ms-sponsored-product-tag {
  align-items: flex-start;
  border-top-right-radius: 6px;
  color: #fff;
  display: flex;
  font-size: 17px;
  height: 100px;
  justify-content: flex-end;
  left: 0;
  padding-right: 4px;
  padding-top: 2px;
  position: absolute;
  top: 0;
  width: 100px;
  z-index: 1;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-fixed-top .ms-sponsored-supplier .ms-sponsored-product-tag .ms-sponsored-tag-bg-wrapper {
  border-top-right-radius: 6px;
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 100px;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-fixed-top .ms-sponsored-supplier .ms-sponsored-product-tag .ms-sponsored-tag-bg-wrapper .ms-sponsored-product-tag-bg {
  border-right: 100px solid transparent;
  border-top: 60px solid #fabc00;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-fixed-top .ms-sponsored-supplier .ms-sponsored-product-tag .ms-sponsored-tag-text-box {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 700;
  justify-content: center;
  left: 5px;
  position: absolute;
  text-transform: uppercase;
  top: 17px;
  transform: rotate(-31deg);
  white-space: nowrap;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-fixed-top .ms-sponsored-supplier .ms-sponsored-product-image-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  height: 100%;
  justify-content: center;
  position: relative;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-fixed-top .ms-sponsored-supplier .ms-sponsored-product-image-wrapper .ms-supplier-product-image {
  align-self: stretch;
  bottom: 0%;
  flex: 0 0 auto;
  height: 100%;
  left: 0%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: 50% 0%;
     object-position: 50% 0%;
  position: absolute;
  right: 0%;
  top: 0%;
  width: 100%;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-fixed-top .ms-sponsored-supplier .ms-product-row-column {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-fixed-top .ms-sponsored-supplier .ms-product-row-column .ms-sponsored-product-top {
  display: flex;
  flex: none;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-fixed-top .ms-sponsored-supplier .ms-product-row-column .ms-sponsored-product-top .ms-sponsor-logo {
  align-self: flex-start;
  max-height: 40px;
  -o-object-fit: contain;
     object-fit: contain;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-fixed-top .ms-sponsored-supplier .ms-product-row-column .ms-sponsored-product-top .ms-sponsor-product-title {
  cursor: pointer;
  flex: 0 0 auto;
  font-size: 15px;
  font-weight: 500;
  font-weight: 600;
  line-height: 120%;
  margin-bottom: 5px;
  max-width: 700px;
  transition: color 200ms ease;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-fixed-top .ms-sponsored-supplier .ms-product-row-column .ms-sponsored-product-top .ms-sponsor-product-description {
  color: #676767;
  flex: 0 0 auto;
  font-size: 12px;
  max-width: 700px;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-fixed-top .ms-sponsored-supplier .ms-product-row-column .ms-sponsored-product-bottom {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-fixed-top .ms-sponsored-supplier .ms-product-row-column .ms-sponsored-product-bottom .ms-sponsored-product-bottom-content {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  min-width: 180px;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-fixed-top .ms-sponsored-supplier .ms-product-row-column .ms-sponsored-product-bottom .ms-sponsored-product-bottom-content .ms-sponsored-product-price-wrapper {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-fixed-top .ms-sponsored-supplier .ms-product-row-column .ms-sponsored-product-bottom .ms-sponsored-product-bottom-content .ms-sponsored-product-price-wrapper .ms-sponsored-product-price {
  font-size: 25px;
  font-weight: 500;
  line-height: 100%;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-fixed-top .ms-sponsored-supplier .ms-product-row-column .ms-sponsored-product-bottom .ms-sponsored-product-bottom-content .ms-sponsored-product-price-wrapper .ms-product-price-details {
  align-items: flex-start;
  align-self: stretch;
  border-left: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  font-size: 11px;
  justify-content: center;
  line-height: 100%;
  margin-left: 10px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-top: 3px;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-fixed-top .ms-sponsored-supplier .ms-product-row-column .ms-sponsored-product-bottom .ms-sponsored-product-bottom-content .ms-sponsored-product-price-wrapper .ms-product-price-details .ms-product-price-detail {
  margin-bottom: 2px;
  margin-top: 2px;
  text-transform: capitalize;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-fixed-top .ms-sponsored-supplier .ms-product-row-column .ms-sponsored-product-bottom .ms-sponsored-product-bottom-content .ms-sponsor-product-features {
  display: flex;
  flex-direction: column;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-fixed-top .ms-sponsored-supplier .ms-product-row-column .ms-sponsored-product-bottom .ms-sponsored-product-bottom-content .ms-sponsor-product-features .ms-product-feature {
  align-self: stretch;
  color: #666;
  display: flex;
  margin-top: 7px;
  position: relative;
  text-align: left;
  white-space: normal;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-fixed-top .ms-sponsored-supplier .ms-product-row-column .ms-sponsored-product-bottom .ms-sponsored-product-bottom-content .ms-sponsor-product-features .ms-product-feature .ms-product-feature-icon {
  align-self: center;
  display: flex;
  flex: 0 0 auto;
  font-family: LineAwesome, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  margin-right: 3px;
  position: relative;
  top: -1px;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-fixed-top .ms-sponsored-supplier .ms-product-row-column .ms-sponsored-product-bottom .ms-sponsored-product-bottom-content .ms-sponsor-product-features .ms-product-feature .ms-product-feature-text {
  flex: 1;
  font-size: 11px;
  font-weight: 500;
  line-height: 100%;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-fixed-top .ms-sponsored-supplier .ms-product-row-column .ms-add-to-cart-wrapper {
  align-items: stretch;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  margin-top: 10px;
  position: relative;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-fixed-top .ms-sponsored-supplier .ms-product-row-column .ms-add-to-cart-wrapper .ms-add-to-cart-input-field {
  flex-grow: 1;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-fixed-top .ms-sponsored-supplier .ms-product-row-column .ms-add-to-cart-wrapper .ms-add-to-cart-input-field input {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 6px 0 0 6px;
  border-right: 1px none #000;
  color: #333;
  display: block;
  font-size: 12px;
  height: 40px;
  line-height: 1.42857143;
  margin-bottom: 0;
  min-width: 110px;
  padding: 8px 12px;
  width: 100%;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-fixed-top .ms-sponsored-supplier .ms-product-row-column .ms-add-to-cart-wrapper .ms-add-to-cart-input-field input:focus {
  border: 1px solid #ccc;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 0;
  border-right: 1px none #000;
  border-top-left-radius: 6px;
  border-top-right-radius: 0;
  box-shadow: none;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-fixed-top .ms-sponsored-supplier .ms-product-row-column .ms-product-feature {
  align-self: stretch;
  color: #666;
  display: flex;
  margin-top: 7px;
  position: relative;
  text-align: left;
  white-space: normal;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-fixed-top .ms-sponsored-supplier .ms-product-row-column .ms-product-feature .ms-product-feature-icon {
  align-self: center;
  display: flex;
  flex: 0 0 auto;
  font-family: LineAwesome, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  margin-right: 3px;
  position: relative;
  top: -1px;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-fixed-top .ms-sponsored-supplier .ms-product-row-column .ms-product-feature .ms-product-feature-text {
  flex: 1;
  font-size: 11px;
  font-weight: 500;
  line-height: 100%;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-suppliers .ms-supplier-options-header {
  align-items: center;
  background-color: #ecf2f9;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  min-height: 50px;
  padding: 20px;
  position: relative;
  z-index: 2;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-suppliers .ms-supplier-options-header .ms-supplier-options-header-left {
  display: flex;
  flex-direction: column;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-suppliers .ms-supplier-options-header .ms-supplier-options-header-left .ms-supplier-options-title {
  font-size: 15px;
  font-weight: 500;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-suppliers .ms-supplier-option-wrapper {
  background-color: #fff;
  border-color: #ccc;
  border-style: none none solid;
  border-width: 1px;
  display: flex;
  justify-content: space-between;
  min-height: 110px;
  overflow: hidden;
  padding: 20px;
  position: relative;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-suppliers .ms-supplier-option-wrapper .ms-supplier-option-left {
  align-items: flex-start;
  display: flex;
  flex: 0 auto;
  justify-content: center;
  min-width: 100px;
  position: relative;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-suppliers .ms-supplier-option-wrapper .ms-supplier-option-left img {
  align-self: stretch;
  bottom: 0%;
  cursor: pointer;
  flex: 0 0 auto;
  height: 100%;
  left: 0%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: 50% 0%;
     object-position: 50% 0%;
  position: absolute;
  right: 0%;
  top: 0%;
  width: 100%;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-suppliers .ms-supplier-option-wrapper .ms-supplier-option-middle {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-suppliers .ms-supplier-option-wrapper .ms-supplier-option-middle .ms-supplier-name {
  cursor: pointer;
  flex: 0 0 auto;
  font-size: 15px;
  font-weight: 500;
  line-height: 120%;
  max-width: 700px;
  transition: color 200ms ease;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-suppliers .ms-supplier-option-wrapper .ms-supplier-option-middle .ms-product-feature {
  align-self: stretch;
  color: #666;
  display: flex;
  margin-top: 7px;
  position: relative;
  text-align: left;
  white-space: normal;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-suppliers .ms-supplier-option-wrapper .ms-supplier-option-middle .ms-product-feature.green {
  color: #07860b;
  font-weight: 600;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-suppliers .ms-supplier-option-wrapper .ms-supplier-option-middle .ms-product-feature .ms-product-feature-icon {
  align-self: center;
  display: flex;
  flex: 0 0 auto;
  font-family: LineAwesome, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  margin-right: 3px;
  position: relative;
  top: -1px;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-suppliers .ms-supplier-option-wrapper .ms-supplier-option-middle .ms-product-feature .ms-product-feature-text {
  flex: 1;
  font-size: 11px;
  font-weight: 500;
  line-height: 100%;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-suppliers .ms-supplier-option-wrapper .ms-supplier-option-right {
  display: flex;
  flex: 0 auto;
  flex-direction: column;
  justify-content: center;
  max-width: 233px;
  width: 100%;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-suppliers .ms-supplier-option-wrapper .ms-supplier-option-right .ms-supplier-option-right-content {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-suppliers .ms-supplier-option-wrapper .ms-supplier-option-right .ms-supplier-option-right-content .ms-supplier-product-price-wrapper {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-suppliers .ms-supplier-option-wrapper .ms-supplier-option-right .ms-supplier-option-right-content .ms-supplier-product-price-wrapper .ms-supplier-product-price {
  font-size: 25px;
  font-weight: 500;
  line-height: 100%;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-suppliers .ms-supplier-option-wrapper .ms-supplier-option-right .ms-supplier-option-right-content .ms-supplier-product-price-wrapper .ms-product-price-details {
  align-items: flex-start;
  align-self: stretch;
  border-left: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  font-size: 11px;
  justify-content: center;
  line-height: 100%;
  margin-left: 10px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-top: 3px;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-suppliers .ms-supplier-option-wrapper .ms-supplier-option-right .ms-supplier-option-right-content .ms-supplier-product-price-wrapper .ms-product-price-details .product-price-detail {
  margin-bottom: 2px;
  margin-top: 2px;
  text-transform: capitalize;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-suppliers .ms-supplier-option-wrapper .ms-supplier-option-right .ms-supplier-option-right-content .ms-add-to-cart-wrapper {
  align-items: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  margin-top: 10px;
  position: relative;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-suppliers .ms-supplier-option-wrapper .ms-supplier-option-right .ms-supplier-option-right-content .ms-add-to-cart-wrapper .ms-add-to-cart-input-field {
  flex-grow: 1;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-suppliers .ms-supplier-option-wrapper .ms-supplier-option-right .ms-supplier-option-right-content .ms-add-to-cart-wrapper .ms-add-to-cart-input-field input {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 6px 0 0 6px;
  border-right: 1px none #000;
  color: #333;
  display: block;
  font-size: 12px;
  height: 40px;
  line-height: 1.42857143;
  margin-bottom: 0;
  min-width: 110px;
  padding: 8px 12px;
  width: 100%;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-suppliers .ms-supplier-option-wrapper .ms-supplier-option-right .ms-supplier-option-right-content .ms-add-to-cart-wrapper .ms-add-to-cart-input-field input:focus {
  border: 1px solid #ccc;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 0;
  border-right: 1px none #000;
  border-top-left-radius: 6px;
  border-top-right-radius: 0;
  box-shadow: none;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-suppliers .ms-supplier-option-wrapper .ms-supplier-option-right .ms-supplier-option-right-content .ms-product-feature {
  align-self: stretch;
  color: #666;
  display: flex;
  margin-top: 7px;
  position: relative;
  text-align: left;
  white-space: normal;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-suppliers .ms-supplier-option-wrapper .ms-supplier-option-right .ms-supplier-option-right-content .ms-product-feature.green {
  color: #07860b;
  font-weight: 600;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-suppliers .ms-supplier-option-wrapper .ms-supplier-option-right .ms-supplier-option-right-content .ms-product-feature .ms-product-feature-icon {
  align-self: center;
  display: flex;
  flex: 0 0 auto;
  font-family: LineAwesome, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  margin-right: 3px;
  position: relative;
  top: -1px;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-suppliers .ms-supplier-option-wrapper .ms-supplier-option-right .ms-supplier-option-right-content .ms-product-feature .ms-product-feature-text {
  flex: 1;
  font-size: 11px;
  font-weight: 500;
  line-height: 100%;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-suppliers .ms-supplier-option-wrapper .ms-product-tag {
  align-items: flex-start;
  border-top-right-radius: 6px;
  color: #fff;
  display: flex;
  font-size: 17px;
  height: 30px;
  justify-content: flex-end;
  padding-right: 4px;
  padding-top: 2px;
  position: absolute;
  right: -1px;
  top: -1px;
  width: 30px;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-suppliers .ms-supplier-option-wrapper .ms-product-tag .ms-supplier-tag-bg-wrap {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-suppliers .ms-supplier-option-wrapper .ms-product-tag .ms-supplier-tag-bg-wrap .ms-product-tag-bg {
  border-left: 30px solid rgba(7, 134, 11, 0);
  border-top: 30px solid #3b128d;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-suppliers .ms-supplier-option-wrapper .ms-product-tag .ms-supplier-tag-bg-wrap .ms-product-tag-bg.ms-alt-color {
  border-top-color: #00affa;
}
.ms-supplier-window .ms-supplier-window-content .ms-modal-suppliers .ms-supplier-option-wrapper .ms-product-tag .ms-product-tag-icon {
  font-family: LineAwesome, sans-serif;
  height: 15px;
  -o-object-fit: contain;
     object-fit: contain;
  position: relative;
  right: -1px;
  width: 12px;
}
.ms-supplier-window .ms-add-to-cart-button {
  align-items: center;
  background-color: #7958bd;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.15), hsla(0deg, 0%, 100%, 0));
  border-color: #ccc;
  border-radius: 0 6px 6px 0;
  border-style: solid solid solid none;
  border-width: 1px;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex: 0 0 auto;
  font-size: 19px;
  justify-content: flex-start;
  padding: 3px 10px;
  position: relative;
  transition: color 200ms ease, background-color 200ms ease;
}
.ms-supplier-window .ms-add-to-cart-button:hover {
  background-color: #7958bd;
  background-image: linear-gradient(90deg, rgba(0, 175, 250, 0.34), rgba(59, 18, 141, 0.23));
  color: #fff;
}
.ms-supplier-window .ms-add-to-cart-button .ms-add-to-cart-icon {
  align-items: center;
  align-self: center;
  display: flex;
  flex: 0 0 auto;
  font-family: LineAwesome, sans-serif;
  font-size: 20px;
  height: 24px;
  justify-content: center;
  margin-bottom: 1px;
  width: 24px;
}
.ms-supplier-window .ms-add-to-cart-button .ms-add-to-cart-text {
  align-self: center;
  flex: 1;
  font-size: 12px;
  font-weight: 400;
  line-height: 11px;
  margin-left: 2px;
  margin-right: 10px;
  text-align: center;
}

@media screen and (max-width: 991px) {
  .ms-supplier-window .ms-supplier-window-content .ms-modal-fixed-top .ms-sponsored-supplier .ms-product-row-column .ms-sponsored-product-top {
    flex: none;
  }
  .ms-supplier-window .ms-supplier-window-content .ms-modal-fixed-top .ms-sponsored-supplier .ms-product-row-column .ms-sponsored-product-top .ms-sponsor-product-description {
    display: none;
  }
}

.shop-right-header {
  align-items: center;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-bottom: 8px;
  position: relative;
}
.shop-right-header .listing-header-wrapper {
  align-self: center;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.shop-right-header .filter-tag-wrapper {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  height: 31px;
  justify-content: flex-start;
}
.shop-right-header .filter-collapse {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-family: LineAwesome, sans-serif;
  font-size: 18px;
  height: 24px;
  justify-content: center;
  left: -3px;
  line-height: 20px;
  margin-right: 5px;
  padding: 1px 15px;
  position: relative;
  transition: left 200ms ease, color 200ms ease;
  width: 24px;
}
.shop-right-header .dynamic-results-title {
  align-self: center;
  color: #24385b;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 0;
  margin-right: 5px;
  margin-top: 0;
  text-align: left;
  text-transform: capitalize;
}
.shop-right-header .save-this-search {
  align-self: stretch;
  background-color: #24385b;
  border-color: #24385b;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  color: #fff;
  cursor: pointer;
  display: none;
  flex: 0 auto;
  justify-content: space-between;
  line-height: 14px;
  margin-left: 5px;
  padding-left: 8px;
  padding-right: 8px;
}
.shop-right-header .save-search {
  align-items: center;
  align-self: center;
  cursor: pointer;
  display: flex;
  margin-left: 2px;
  padding-left: 3px;
  padding-right: 5px;
}
.shop-right-header .follow {
  align-self: stretch;
  background-color: #62799b;
  border-color: #62799b;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  color: #fff;
  cursor: pointer;
  display: none;
  flex: 0 auto;
  justify-content: space-between;
  line-height: 14px;
  margin-left: 5px;
  padding-left: 8px;
  padding-right: 8px;
}
.shop-right-header .sort-dropdown {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  z-index: auto;
}
.shop-right-header .combo-action-box {
  align-self: center;
  border-color: #ccc;
  border-radius: 3px;
  border-style: none;
  border-width: 1px;
  box-shadow: none;
  display: flex;
  margin-left: 9px;
  overflow: hidden;
}
.shop-right-header .action-button.muted.active {
  color: #7958bd;
}
.shop-right-header .action-button.muted {
  background-color: hsla(0deg, 0%, 100%, 0);
  border-color: #ccc;
  border-radius: 3px;
  border-style: none;
  border-width: 1px;
  box-shadow: none;
  color: #a5a5a5;
  font-size: 12px;
  line-height: 16px;
  margin-left: 0;
  margin-right: 0;
  min-height: auto;
  padding: 5px;
  position: relative;
}
.shop-right-header .action-button {
  align-items: center;
  align-self: center;
  background-color: #24385b;
  border-radius: 8px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.13);
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 400;
  justify-content: center;
  margin-left: 0;
  margin-right: 0;
  min-height: auto;
  padding: 4px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 200ms ease;
  white-space: nowrap;
}
.shop-right-header .action-icon {
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
}
.shop-right-header .divider.margin-left.margin-right {
  border-right-style: dotted;
  margin-left: 10px;
  margin-right: 10px;
}
.shop-right-header .divider.margin-left {
  margin-left: 20px;
}
.shop-right-header .divider {
  align-self: center;
  border-right: 1px dashed rgba(0, 0, 0, 0.1);
  flex: 0 0 auto;
  height: 30px;
  width: 1px;
}
@media screen and (min-width: 1440px) {
  .shop-right-header .title-line {
    display: block;
  }
}
.shop-right-header .title-line {
  border-bottom: 1px dotted #ccc;
  bottom: 0;
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
}